import { type ColumnType, type Selectable } from 'kysely'
import { z } from 'zod'

import { serviceContractId, type ServiceContractId } from './ServiceContract.js'

/** Represents the view public.remaining_contract_balance_view_ */
export default interface RemainingContractBalanceViewTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<ServiceContractId, never, never>

  /** Database type: pg_catalog.int4 */
  contractNumber: ColumnType<number, never, never>

  /** Database type: pg_catalog.numeric */
  totalDue: ColumnType<number, never, never>
}

export type RemainingContractBalanceView =
  Selectable<RemainingContractBalanceViewTable>

export const remainingContractBalanceView = z.object({
  id            : serviceContractId,
  contractNumber: z.number(),
  totalDue      : z.number(),
}) as unknown as z.Schema<RemainingContractBalanceView>
