import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  productServiceHistoryId,
  type ProductServiceHistoryId,
} from './ProductServiceHistory.js'
import { ServiceContractId } from './ServiceContract.js'
import { userId, type UserId } from './User.js'

/** Identifier type for public.service_contract_item_ */
export type ServiceContractItemId = string & {
  __brand: 'ServiceContractItemId'
}

/** Represents the table public.service_contract_item_ */
export default interface ServiceContractItemTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<
    ServiceContractItemId,
    ServiceContractItemId | undefined,
    ServiceContractItemId
  >

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.uuid */
  serviceContractId: ColumnType<
    ServiceContractId,
    ServiceContractId,
    ServiceContractId
  >

  /** Database type: pg_catalog.timestamptz */
  trialEndedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: pg_catalog.timestamptz */
  trialStartedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: pg_catalog.timestamptz */
  serviceStartDate: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: pg_catalog.timestamptz */
  serviceEndDate: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: pg_catalog.uuid */
  productServiceHistoryId: ColumnType<
    ProductServiceHistoryId,
    ProductServiceHistoryId,
    ProductServiceHistoryId
  >

  /** Database type: pg_catalog.int4 */
  serviceQuantity: ColumnType<number, number, number>

  /** Database type: pg_catalog.int4 */
  serviceDuration: ColumnType<number, number, number>

  /** Database type: pg_catalog.text */
  notes: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  serviceExpiredAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: pg_catalog.timestamptz */
  serviceDateRevenueStartedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: pg_catalog.timestamptz */
  serviceDateRevenueEndedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >
}

export type ServiceContractItem = Selectable<ServiceContractItemTable>

export type ServiceContractItemInitializer =
  Insertable<ServiceContractItemTable>

export type ServiceContractItemMutator = Updateable<ServiceContractItemTable>

export const serviceContractItemId =
  z.string() as unknown as z.Schema<ServiceContractItemId>

export const serviceContractItem = z.object({
  id                         : serviceContractItemId,
  createdAt                  : z.coerce.date().nullable().optional(),
  createdBy                  : userId.nullable().optional(),
  updatedAt                  : z.coerce.date().nullable().optional(),
  updatedBy                  : userId.nullable().optional(),
  deletedAt                  : z.coerce.date().nullable().optional(),
  deletedBy                  : userId.nullable().optional(),
  serviceContractId          : z.string(),
  trialEndedAt               : z.coerce.date().nullable().optional(),
  trialStartedAt             : z.coerce.date().nullable().optional(),
  serviceStartDate           : z.coerce.date().nullable().optional(),
  serviceEndDate             : z.coerce.date().nullable().optional(),
  productServiceHistoryId    : productServiceHistoryId,
  serviceQuantity            : z.number(),
  serviceDuration            : z.number(),
  notes                      : z.string().nullable().optional(),
  serviceExpiredAt           : z.coerce.date().nullable().optional(),
  serviceDateRevenueStartedAt: z.coerce.date().nullable().optional(),
  serviceDateRevenueEndedAt  : z.coerce.date().nullable().optional(),
}) as unknown as z.Schema<ServiceContractItem>

export const serviceContractItemInitializer = z.object({
  id                         : serviceContractItemId.optional(),
  createdAt                  : z.coerce.date().optional().nullable().optional(),
  createdBy                  : userId.optional().nullable().optional(),
  updatedAt                  : z.coerce.date().optional().nullable().optional(),
  updatedBy                  : userId.optional().nullable().optional(),
  deletedAt                  : z.coerce.date().optional().nullable().optional(),
  deletedBy                  : userId.optional().nullable().optional(),
  serviceContractId          : z.string(),
  trialEndedAt               : z.coerce.date().optional().nullable().optional(),
  trialStartedAt             : z.coerce.date().optional().nullable().optional(),
  serviceStartDate           : z.coerce.date().optional().nullable().optional(),
  serviceEndDate             : z.coerce.date().optional().nullable().optional(),
  productServiceHistoryId    : productServiceHistoryId,
  serviceQuantity            : z.number(),
  serviceDuration            : z.number(),
  notes                      : z.string().optional().nullable().optional(),
  serviceExpiredAt           : z.coerce.date().optional().nullable().optional(),
  serviceDateRevenueStartedAt: z.coerce.date().optional().nullable().optional(),
  serviceDateRevenueEndedAt  : z.coerce.date().optional().nullable().optional(),
}) as unknown as z.Schema<ServiceContractItemInitializer>

export const serviceContractItemMutator = z.object({
  id                         : serviceContractItemId.optional(),
  createdAt                  : z.coerce.date().optional().nullable().optional(),
  createdBy                  : userId.optional().nullable().optional(),
  updatedAt                  : z.coerce.date().optional().nullable().optional(),
  updatedBy                  : userId.optional().nullable().optional(),
  deletedAt                  : z.coerce.date().optional().nullable().optional(),
  deletedBy                  : userId.optional().nullable().optional(),
  serviceContractId          : z.string().optional(),
  trialEndedAt               : z.coerce.date().optional().nullable().optional(),
  trialStartedAt             : z.coerce.date().optional().nullable().optional(),
  serviceStartDate           : z.coerce.date().optional().nullable().optional(),
  serviceEndDate             : z.coerce.date().optional().nullable().optional(),
  productServiceHistoryId    : productServiceHistoryId.optional(),
  serviceQuantity            : z.number().optional(),
  serviceDuration            : z.number().optional(),
  notes                      : z.string().optional().nullable().optional(),
  serviceExpiredAt           : z.coerce.date().optional().nullable().optional(),
  serviceDateRevenueStartedAt: z.coerce.date().optional().nullable().optional(),
  serviceDateRevenueEndedAt  : z.coerce.date().optional().nullable().optional(),
}) as unknown as z.Schema<ServiceContractItemMutator>
