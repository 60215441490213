import { z } from 'zod'

/** Represents the enum public.service_payment_type_ */
enum ServicePaymentType {
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  WIRE_TRASNFER = 'WIRE_TRASNFER',
  CASH = 'CASH',
  OTHER = 'OTHER',
}

export default ServicePaymentType

/** Zod schema for service_payment_type_ */
export const servicePaymentType = z.enum([
  'CREDIT_CARD',
  'DEBIT_CARD',
  'WIRE_TRASNFER',
  'CASH',
  'OTHER',
])
