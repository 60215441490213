import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  labelSortType,
  type default as LabelSortType,
} from './LabelSortType.js'
import {
  lengthUnitType,
  type default as LengthUnitType,
} from './LengthUnitType.js'
import {
  siteStatusType,
  type default as SiteStatusType,
} from './SiteStatusType.js'
import { tempUnitType, type default as TempUnitType } from './TempUnitType.js'

/** Identifier type for public.site_ */
export type SiteId = number & { __brand: 'SiteId' }

/** Represents the table public.site_ */
export default interface SiteTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<SiteId, SiteId | undefined, SiteId>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  siteName: ColumnType<string, string, string>

  /** Database type: pg_catalog.bool */
  visible: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.int4 */
  sortOrder: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.bool */
  includesArchivedData: ColumnType<
    boolean | null,
    boolean | null,
    boolean | null
  >

  /** Database type: pg_catalog.text */
  internalNotes: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  customerNotes: ColumnType<string | null, string | null, string | null>

  /** Database type: public.length_unit_type_ */
  lengthUnits: ColumnType<LengthUnitType, LengthUnitType, LengthUnitType>

  /** Database type: public.temp_unit_type_ */
  tempUnits: ColumnType<TempUnitType, TempUnitType, TempUnitType>

  /** Database type: pg_catalog.numeric */
  geoLat: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.numeric */
  geoLon: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.numeric */
  geoElev: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.text */
  embeddedUuid: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.int4 */
  airtempDecode: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.int4 */
  sonicHeight: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.bool */
  labelSettingShowCableSerial: ColumnType<
    boolean | null,
    boolean | null,
    boolean | null
  >

  /** Database type: pg_catalog.int4 */
  readingsVersion: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.bool */
  useRecordDate: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: public.label_sort_type_ */
  labelSettingSortBy: ColumnType<
    LabelSortType | null,
    LabelSortType | null,
    LabelSortType | null
  >

  /** Database type: public.site_status_type_ */
  status: ColumnType<
    SiteStatusType | null,
    SiteStatusType | null,
    SiteStatusType | null
  >
}

export type Site = Selectable<SiteTable>

export type SiteInitializer = Insertable<SiteTable>

export type SiteMutator = Updateable<SiteTable>

export const siteId = z.number() as unknown as z.Schema<SiteId>

export const site = z.object({
  id                         : siteId,
  createdAt                  : z.coerce.date().nullable().optional(),
  createdBy                  : z.string().nullable().optional(),
  updatedAt                  : z.coerce.date().nullable().optional(),
  updatedBy                  : z.string().nullable().optional(),
  deletedAt                  : z.coerce.date().nullable().optional(),
  deletedBy                  : z.string().nullable().optional(),
  siteName                   : z.string(),
  visible                    : z.boolean().nullable().optional(),
  sortOrder                  : z.number().nullable().optional(),
  includesArchivedData       : z.boolean().nullable().optional(),
  internalNotes              : z.string().nullable().optional(),
  customerNotes              : z.string().nullable().optional(),
  lengthUnits                : lengthUnitType,
  tempUnits                  : tempUnitType,
  geoLat                     : z.number().nullable().optional(),
  geoLon                     : z.number().nullable().optional(),
  geoElev                    : z.number().nullable().optional(),
  embeddedUuid               : z.string().nullable().optional(),
  airtempDecode              : z.number().nullable().optional(),
  sonicHeight                : z.number().nullable().optional(),
  labelSettingShowCableSerial: z.boolean().nullable().optional(),
  readingsVersion            : z.number().nullable().optional(),
  useRecordDate              : z.boolean().nullable().optional(),
  labelSettingSortBy         : labelSortType.nullable().optional(),
  status                     : siteStatusType.nullable().optional(),
}) as unknown as z.Schema<Site>

export const siteInitializer = z.object({
  id                         : siteId.optional(),
  createdAt                  : z.coerce.date().optional().nullable().optional(),
  createdBy                  : z.string().optional().nullable().optional(),
  updatedAt                  : z.coerce.date().optional().nullable().optional(),
  updatedBy                  : z.string().optional().nullable().optional(),
  deletedAt                  : z.coerce.date().optional().nullable().optional(),
  deletedBy                  : z.string().optional().nullable().optional(),
  siteName                   : z.string(),
  visible                    : z.boolean().optional().nullable().optional(),
  sortOrder                  : z.number().optional().nullable().optional(),
  includesArchivedData       : z.boolean().optional().nullable().optional(),
  internalNotes              : z.string().optional().nullable().optional(),
  customerNotes              : z.string().optional().nullable().optional(),
  lengthUnits                : lengthUnitType,
  tempUnits                  : tempUnitType,
  geoLat                     : z.number().optional().nullable().optional(),
  geoLon                     : z.number().optional().nullable().optional(),
  geoElev                    : z.number().optional().nullable().optional(),
  embeddedUuid               : z.string().optional().nullable().optional(),
  airtempDecode              : z.number().optional().nullable().optional(),
  sonicHeight                : z.number().optional().nullable().optional(),
  labelSettingShowCableSerial: z.boolean().optional().nullable().optional(),
  readingsVersion            : z.number().optional().nullable().optional(),
  useRecordDate              : z.boolean().optional().nullable().optional(),
  labelSettingSortBy         : labelSortType.optional().nullable().optional(),
  status                     : siteStatusType.optional().nullable().optional(),
}) as unknown as z.Schema<SiteInitializer>

export const siteMutator = z.object({
  id                         : siteId.optional(),
  createdAt                  : z.coerce.date().optional().nullable().optional(),
  createdBy                  : z.string().optional().nullable().optional(),
  updatedAt                  : z.coerce.date().optional().nullable().optional(),
  updatedBy                  : z.string().optional().nullable().optional(),
  deletedAt                  : z.coerce.date().optional().nullable().optional(),
  deletedBy                  : z.string().optional().nullable().optional(),
  siteName                   : z.string().optional(),
  visible                    : z.boolean().optional().nullable().optional(),
  sortOrder                  : z.number().optional().nullable().optional(),
  includesArchivedData       : z.boolean().optional().nullable().optional(),
  internalNotes              : z.string().optional().nullable().optional(),
  customerNotes              : z.string().optional().nullable().optional(),
  lengthUnits                : lengthUnitType.optional(),
  tempUnits                  : tempUnitType.optional(),
  geoLat                     : z.number().optional().nullable().optional(),
  geoLon                     : z.number().optional().nullable().optional(),
  geoElev                    : z.number().optional().nullable().optional(),
  embeddedUuid               : z.string().optional().nullable().optional(),
  airtempDecode              : z.number().optional().nullable().optional(),
  sonicHeight                : z.number().optional().nullable().optional(),
  labelSettingShowCableSerial: z.boolean().optional().nullable().optional(),
  readingsVersion            : z.number().optional().nullable().optional(),
  useRecordDate              : z.boolean().optional().nullable().optional(),
  labelSettingSortBy         : labelSortType.optional().nullable().optional(),
  status                     : siteStatusType.optional().nullable().optional(),
}) as unknown as z.Schema<SiteMutator>
