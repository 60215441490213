import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

/** Identifier type for public.user_ */
export type UserId = string & { __brand: 'UserId' }

/** Represents the table public.user_ */
export default interface UserTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<UserId, UserId | undefined, UserId>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.int4[] */
  oldIds: ColumnType<number[] | null, number[] | null, number[] | null>

  /** Database type: pg_catalog.text */
  namePrefix: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  nameFirst: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  nameMiddle: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  nameLast: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  nameSuffix: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  email: ColumnType<string, string, string>

  /** Database type: pg_catalog.text */
  backupEmail: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  passwordHash: ColumnType<string, string, string>

  /** Database type: pg_catalog.text */
  passwordSalt: ColumnType<string, string, string>

  /** Database type: pg_catalog.timestamptz */
  passwordLastUpdated: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: pg_catalog.text */
  phone: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  isDeleted: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.timestamptz */
  lastLogin: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.timestamptz */
  unsubscribedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: pg_catalog.timestamptz */
  archivedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: pg_catalog.jsonb */
  prefs: ColumnType<unknown | null, unknown | null, unknown | null>
}

export type User = Selectable<UserTable>

export type UserInitializer = Insertable<UserTable>

export type UserMutator = Updateable<UserTable>

export const userId = z.string() as unknown as z.Schema<UserId>

export const user = z.object({
  id                 : userId,
  createdAt          : z.coerce.date().nullable().optional(),
  createdBy          : userId.nullable().optional(),
  updatedAt          : z.coerce.date().nullable().optional(),
  updatedBy          : userId.nullable().optional(),
  deletedAt          : z.coerce.date().nullable().optional(),
  deletedBy          : userId.nullable().optional(),
  oldIds             : z.number().array().nullable().optional(),
  namePrefix         : z.string().nullable().optional(),
  nameFirst          : z.string().nullable().optional(),
  nameMiddle         : z.string().nullable().optional(),
  nameLast           : z.string().nullable().optional(),
  nameSuffix         : z.string().nullable().optional(),
  email              : z.string(),
  backupEmail        : z.string().nullable().optional(),
  passwordHash       : z.string(),
  passwordSalt       : z.string(),
  passwordLastUpdated: z.coerce.date().nullable().optional(),
  phone              : z.string().nullable().optional(),
  isDeleted          : z.boolean().nullable().optional(),
  lastLogin          : z.coerce.date().nullable().optional(),
  unsubscribedAt     : z.coerce.date().nullable().optional(),
  archivedAt         : z.coerce.date().nullable().optional(),
  prefs              : z.unknown().nullable().optional(),
}) as unknown as z.Schema<User>

export const userInitializer = z.object({
  id                 : userId.optional(),
  createdAt          : z.coerce.date().optional().nullable().optional(),
  createdBy          : userId.optional().nullable().optional(),
  updatedAt          : z.coerce.date().optional().nullable().optional(),
  updatedBy          : userId.optional().nullable().optional(),
  deletedAt          : z.coerce.date().optional().nullable().optional(),
  deletedBy          : userId.optional().nullable().optional(),
  oldIds             : z.number().array().optional().nullable().optional(),
  namePrefix         : z.string().optional().nullable().optional(),
  nameFirst          : z.string().optional().nullable().optional(),
  nameMiddle         : z.string().optional().nullable().optional(),
  nameLast           : z.string().optional().nullable().optional(),
  nameSuffix         : z.string().optional().nullable().optional(),
  email              : z.string(),
  backupEmail        : z.string().optional().nullable().optional(),
  passwordHash       : z.string(),
  passwordSalt       : z.string(),
  passwordLastUpdated: z.coerce.date().optional().nullable().optional(),
  phone              : z.string().optional().nullable().optional(),
  isDeleted          : z.boolean().optional().nullable().optional(),
  lastLogin          : z.coerce.date().optional().nullable().optional(),
  unsubscribedAt     : z.coerce.date().optional().nullable().optional(),
  archivedAt         : z.coerce.date().optional().nullable().optional(),
  prefs              : z.unknown().optional().nullable().optional(),
}) as unknown as z.Schema<UserInitializer>

export const userMutator = z.object({
  id                 : userId.optional(),
  createdAt          : z.coerce.date().optional().nullable().optional(),
  createdBy          : userId.optional().nullable().optional(),
  updatedAt          : z.coerce.date().optional().nullable().optional(),
  updatedBy          : userId.optional().nullable().optional(),
  deletedAt          : z.coerce.date().optional().nullable().optional(),
  deletedBy          : userId.optional().nullable().optional(),
  oldIds             : z.number().array().optional().nullable().optional(),
  namePrefix         : z.string().optional().nullable().optional(),
  nameFirst          : z.string().optional().nullable().optional(),
  nameMiddle         : z.string().optional().nullable().optional(),
  nameLast           : z.string().optional().nullable().optional(),
  nameSuffix         : z.string().optional().nullable().optional(),
  email              : z.string().optional(),
  backupEmail        : z.string().optional().nullable().optional(),
  passwordHash       : z.string().optional(),
  passwordSalt       : z.string().optional(),
  passwordLastUpdated: z.coerce.date().optional().nullable().optional(),
  phone              : z.string().optional().nullable().optional(),
  isDeleted          : z.boolean().optional().nullable().optional(),
  lastLogin          : z.coerce.date().optional().nullable().optional(),
  unsubscribedAt     : z.coerce.date().optional().nullable().optional(),
  archivedAt         : z.coerce.date().optional().nullable().optional(),
  prefs              : z.unknown().optional().nullable().optional(),
}) as unknown as z.Schema<UserMutator>
