import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link } from '@chakra-ui/react'
import type { LinkProps } from '@chakra-ui/react'

interface IExternalLinkProps {
  name: string
  href: string
  left?: JSX.Element | boolean
  right?: JSX.Element | boolean
  linkProps?: LinkProps
}

export const ExternalLink = ({
  name,
  href,
  left,
  right,
  linkProps,
}: IExternalLinkProps) => (
  <Link href={href} isExternal {...linkProps}>
    {left ?? null} {name} {right ?? <ExternalLinkIcon mx='2px' />}
  </Link>
)
