import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { projectId, type ProjectId } from './Project.js'
import { userId, type UserId } from './User.js'

/** Identifier type for public.service_contract_ */
export type ServiceContractId = string & { __brand: 'ServiceContractId' }

/** Represents the table public.service_contract_ */
export default interface ServiceContractTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<
    ServiceContractId,
    ServiceContractId | undefined,
    ServiceContractId
  >

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.int4 */
  projectId: ColumnType<ProjectId, ProjectId, ProjectId>

  /** Database type: pg_catalog.int4 */
  contractNumber: ColumnType<number, number | undefined, number>

  /** Database type: pg_catalog.int4 */
  totalPriceInCents: ColumnType<number, number, number>

  /** Database type: pg_catalog.bool */
  rental: ColumnType<boolean, boolean | undefined, boolean>

  /** Database type: pg_catalog.bool */
  sponsored: ColumnType<boolean, boolean | undefined, boolean>

  /** Database type: pg_catalog.text */
  notes: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  invoiceDate: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: pg_catalog.text */
  invoiceNumber: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  internal: ColumnType<boolean, boolean | undefined, boolean>
}

export type ServiceContract = Selectable<ServiceContractTable>

export type ServiceContractInitializer = Insertable<ServiceContractTable>

export type ServiceContractMutator = Updateable<ServiceContractTable>

export const serviceContractId =
  z.string() as unknown as z.Schema<ServiceContractId>

export const serviceContract = z.object({
  id               : serviceContractId,
  createdAt        : z.coerce.date().nullable().optional(),
  createdBy        : userId.nullable().optional(),
  updatedAt        : z.coerce.date().nullable().optional(),
  updatedBy        : userId.nullable().optional(),
  deletedAt        : z.coerce.date().nullable().optional(),
  deletedBy        : userId.nullable().optional(),
  projectId        : projectId,
  contractNumber   : z.number(),
  totalPriceInCents: z.number(),
  rental           : z.boolean(),
  sponsored        : z.boolean(),
  notes            : z.string().nullable().optional(),
  invoiceDate      : z.coerce.date().nullable().optional(),
  invoiceNumber    : z.string().nullable().optional(),
  internal         : z.boolean(),
}) as unknown as z.Schema<ServiceContract>

export const serviceContractInitializer = z.object({
  id               : serviceContractId.optional(),
  createdAt        : z.coerce.date().optional().nullable().optional(),
  createdBy        : userId.optional().nullable().optional(),
  updatedAt        : z.coerce.date().optional().nullable().optional(),
  updatedBy        : userId.optional().nullable().optional(),
  deletedAt        : z.coerce.date().optional().nullable().optional(),
  deletedBy        : userId.optional().nullable().optional(),
  projectId        : projectId,
  contractNumber   : z.number().optional(),
  totalPriceInCents: z.number(),
  rental           : z.boolean().optional(),
  sponsored        : z.boolean().optional(),
  notes            : z.string().optional().nullable().optional(),
  invoiceDate      : z.coerce.date().optional().nullable().optional(),
  invoiceNumber    : z.string().optional().nullable().optional(),
  internal         : z.boolean().optional(),
}) as unknown as z.Schema<ServiceContractInitializer>

export const serviceContractMutator = z.object({
  id               : serviceContractId.optional(),
  createdAt        : z.coerce.date().optional().nullable().optional(),
  createdBy        : userId.optional().nullable().optional(),
  updatedAt        : z.coerce.date().optional().nullable().optional(),
  updatedBy        : userId.optional().nullable().optional(),
  deletedAt        : z.coerce.date().optional().nullable().optional(),
  deletedBy        : userId.optional().nullable().optional(),
  projectId        : projectId.optional(),
  contractNumber   : z.number().optional(),
  totalPriceInCents: z.number().optional(),
  rental           : z.boolean().optional(),
  sponsored        : z.boolean().optional(),
  notes            : z.string().optional().nullable().optional(),
  invoiceDate      : z.coerce.date().optional().nullable().optional(),
  invoiceNumber    : z.string().optional().nullable().optional(),
  internal         : z.boolean().optional(),
}) as unknown as z.Schema<ServiceContractMutator>
