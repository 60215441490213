import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { getIn } from 'forms/helpers/getIn'
import { BaseInputProps } from 'forms/helpers/types'

export type DefaultInputProps = BaseInputProps & {
  type?: string
  readonly?: boolean
  rightAddon?: string | JSX.Element | ((props: any) => JSX.Element)
  leftAddon?: string | JSX.Element | ((props: any) => JSX.Element)
  rightElement?: string | JSX.Element | ((props: any) => JSX.Element)
  leftElement?: string | JSX.Element | ((props: any) => JSX.Element)
}

export const DefaultInput = (props: DefaultInputProps) => {
  const {
    register,
    formState: { errors, touchedFields },
  } = useFormContext()

  return (
    <FormControl
      isRequired={Boolean(props?.hookformProps?.required)}
      isInvalid={Boolean(
        errors &&
          getIn(errors, props.name) &&
          touchedFields &&
          getIn(touchedFields, props.name),
      )}
    >
      <FormLabel htmlFor={props.name}>{props.label ?? props.name}</FormLabel>
      {props.description && <Text>{props.description}</Text>}
      <InputGroup>
        {props.leftAddon && <InputLeftAddon children={props.leftAddon} />}
        {props.leftElement && <InputLeftAddon children={props.leftElement} />}
        <Input
          autoComplete='off'
          hidden={props.hidden ?? false}
          readOnly={props.readonly ?? false}
          type={props.type ?? 'text'}
          tabIndex={props.tabIndex}
          placeholder={props.placeholder ?? props.name}
          {...register(props.name, props.hookformProps)}
        />
        {props.rightElement && (
          <InputRightAddon children={props.rightElement} />
        )}
        {props.rightAddon && <InputRightAddon children={props.rightAddon} />}
      </InputGroup>
      <FormErrorMessage>
        {errors && getIn(errors, props.name)?.message}
      </FormErrorMessage>
    </FormControl>
  )
}
