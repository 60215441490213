import { z } from 'zod'

/** Represents the enum public.product_duration_type_ */
enum ProductDurationType {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export default ProductDurationType

/** Zod schema for product_duration_type_ */
export const productDurationType = z.enum(['MONTH', 'YEAR'])
