import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'
import queryString from 'query-string'

import { API_BASE_URI } from 'constants/api'

import type { Obj, ID } from 'types/common'

import * as apiUtils from '.'

const url = '/reading'

export const get = (id: ID, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/${id}`, options)

export const getAll = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, options)

export const create = (data: Obj) => apiUtils.POST(`${url}/`, data)

export const update = (id: ID, data: Obj) => apiUtils.PUT(`${url}/${id}`, data)

export const remove = (id: ID) => apiUtils.DELETE(`${url}/${id}`)

export const getChartData = (siteId: ID, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({ params: { 'site.id': siteId } }, options))

export const getSparkline = (siteId: ID, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({ params: { 'site.id': siteId } }, options))

export const getLines = (
  type: 'site' | 'project' | 'logger' | 'blueprint' | 'ethernet-activator',
  options: AxiosRequestConfig = {},
) => apiUtils.GET(`${url}/lines`, merge({ params: { type } }, options))

export const getReportData = (
  projectId: ID,
  options: AxiosRequestConfig = {},
) =>
  apiUtils.GET(
    `${url}/`,
    merge({ params: { 'project.id': projectId } }, options),
  )

export const reprocess = (data: Obj, options: AxiosRequestConfig = {}) =>
  apiUtils.PUT(`${url}/reprocess`, data, options)

export const loggerLongevityReport = () =>
  apiUtils.GET(`${url}/logger-longevity`)

export const downloadSiteCsv = (
  siteId: ID,
  options: Obj = { dl: 'csv', ts: 'iso' },
) => {
  const params = merge({ 'site.id': siteId }, options)
  window.open(
    `${API_BASE_URI()}${url}/?${queryString.stringify(params)}`,
    '_blank',
  )
}

export const downloadLoggerCsv = (
  loggerSerial: String,
  options: Obj = { dl: 'timeseries', ts: 'iso' },
) => {
  const params = merge({ loggerSerial }, options)
  window.open(
    `${API_BASE_URI()}${url}/?${queryString.stringify(params)}`,
    '_blank',
  )
}

export const publicPage = {
  get: (id: ID, options: AxiosRequestConfig = {}) =>
    apiUtils.GET(`/public${url}/${id}`, options),
  getAll: (options: AxiosRequestConfig = {}) =>
    apiUtils.GET(`/public${url}/`, options),
  getChartData: (siteId: ID, options: AxiosRequestConfig = {}) =>
    apiUtils.GET(
      `/public${url}/`,
      merge({ params: { 'site.id': siteId } }, options),
    ),
  getLines: (
    type: 'site' | 'project' | 'logger' | 'blueprint',
    options: AxiosRequestConfig = {},
  ) =>
    apiUtils.GET(`/public${url}/lines`, merge({ params: { type } }, options)),
}

export const admin = {
  get: (id: ID, options: AxiosRequestConfig = {}) =>
    get(id, merge({}, apiUtils.globalFlags, options)),
  getAll: (options: AxiosRequestConfig = {}) =>
    getAll(merge({}, apiUtils.globalFlags, options)),
  getChartData: (siteId: ID, options: AxiosRequestConfig = {}) =>
    getChartData(siteId, merge({}, apiUtils.globalFlags, options)),
  getSparkline: (siteId: ID, options: AxiosRequestConfig = {}) =>
    getSparkline(siteId, merge({}, apiUtils.globalFlags, options)),
  getReportData: (projectId: ID, options: AxiosRequestConfig = {}) =>
    getReportData(projectId, merge({}, apiUtils.globalFlags, options)),
}
