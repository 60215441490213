import { default as rawJoi } from 'joi'

import * as common from './common.js'

const extendedJoi = rawJoi.extend((extendedJoi) => ({
  type    : 'cableBody',
  base    : extendedJoi.array().meta({ baseType: 'array' }),
  messages: {
    'cableBody.end' : 'a cable should have an end component',
    'cableBody.body': 'there should not be an end in the middle of the cable',
  },
  rules: {
    cableBody: {
      validate(value, helpers, args, options) {
        const isCableBody = value.every(
          (x, i, arr) => i === arr.length - 1 || x.mold.type !== 'end',
        )
        const isCableEnd = value[value.length - 1].mold.type === 'end'

        if (!isCableBody) return helpers.error('cableBody.body')
        if (!isCableEnd) return helpers.error('cableBody.end')

        return value
      },
    },
  },
}))

export const cableEvent = extendedJoi.object({
  action: extendedJoi
    .string()
    .valid(
      'calibrate',
      'Downloaded cutter file',
      'manually-flagged issue',
      'auto-flagged issue',
    )
    .required(),

  notes   : extendedJoi.string().empty('').allow(null),
  operator: extendedJoi.string().empty('').allow(null),

  date          : extendedJoi.date().iso().greater('2001-01-01T00:00:00Z'),
  calibration_id: extendedJoi.number().integer(),

  by_user: common.BY_USER,
})

export const cableUse = extendedJoi.object({
  begin  : extendedJoi.date().iso().greater('2001-01-01T00:00:00Z'),
  end    : extendedJoi.date().iso().greater('2001-01-01T00:00:00Z'),
  org    : { id: extendedJoi.number().integer() },
  project: { id: extendedJoi.number().integer() },
  site   : { id: extendedJoi.number().integer() },
  by_user: common.BY_USER,
})

export const leadComponent = extendedJoi
  .string()
  .regex(/^(no mlink)|(mlink)|(bare)|(xlr)|(raymo)|(none)$/)
  .required()
export const bodyComponent = extendedJoi
  .string()
  .regex(/^(Sensor \d+)|(Protection\s\/\sSensor 1)|(zero marker)|(none)$/)
  .required()
export const moldType = extendedJoi
  .string()
  .valid('straight', 'none', '90degree', 'end')
  .required()
export const moldColor = extendedJoi
  .string()
  .valid('blue', 'white', 'black', 'none')
  .required()
export const cableColor = extendedJoi
  .string()
  .valid('Standard', 'Armored', 'White', 'Red', 'Wires', 'No Cable')
  .required()
const length = extendedJoi.number().integer().required()
const position = extendedJoi.number().integer().required()
const conduit = extendedJoi
  .string()
  .valid('none', 'flex', 'pex', 'pexSand', 'hdpe', 'stainless')
  .empty('')
  .allow(null)

export const cable = extendedJoi.object({
  id: extendedJoi.number().integer(),

  name: extendedJoi.string().required(),

  by_user: common.BY_USER,

  notes: extendedJoi.string().empty('').allow(null),

  productionComment: extendedJoi.string().empty('').allow(null),

  status: extendedJoi
    .string()
    .valid(
      'Draft',
      'Pre-build',
      'Built',
      'Active',
      'Disassembled',
      'Dead',
      'Unknown',
    )
    .empty('')
    .allow(null),

  packaging: extendedJoi.string().empty('').allow(null),

  heatshrink: extendedJoi.string().valid('none', 'black', 'white').required(),

  connector: extendedJoi.string().valid('xlr', 'raymo', 'bare').required(),

  mlink: extendedJoi.boolean().default(false),

  eeprom: extendedJoi.array().items(
    extendedJoi.object({
      id              : extendedJoi.string().min(16).max(16).hex().uppercase().required(),
      paired_sensor_id: extendedJoi
        .string()
        .min(16)
        .max(16)
        .hex()
        .uppercase()
        .required(),
    }),
    extendedJoi.object({
      id              : extendedJoi.string().min(16).max(16).hex().uppercase().required(),
      paired_sensor_id: extendedJoi.string().valid('NONE').required(),
    }),
  ),

  quantity: extendedJoi.number().integer().required(),

  lead: extendedJoi
    .array()
    .items({
      type      : 'lead',
      length,
      position,
      cableColor: cableColor,
      conduit,
      component : leadComponent,
    })
    .required(),

  body: extendedJoi
    .cableBody()
    .cableBody()
    .items({
      type     : 'body',
      component: bodyComponent,
      position,
      length,
      order    : extendedJoi.number().integer().required(),
      mold     : extendedJoi.object({
        type : moldType,
        color: moldColor,
      }),
      cableColor: cableColor,
      conduit,
    })
    .required(),

  locked: extendedJoi.boolean().required(),

  panelBox  : extendedJoi.boolean(),
  rigidProbe: extendedJoi.boolean(),
  sdm       : extendedJoi.boolean(),

  serial: extendedJoi.number().integer(),

  ts: common.TS,
  v : extendedJoi.number().integer(),

  priceChart: {
    sensor    : extendedJoi.number(),
    heatshrink: {
      black: extendedJoi.number().required(),
      white: extendedJoi.number().required(),
      none : extendedJoi.number().required(),
    },
    standardCable: {
      ft: extendedJoi.number(),
      m : extendedJoi.number(),
      in: extendedJoi.number(),
      cm: extendedJoi.number(),
      mm: extendedJoi.number(),
    },
    armoredCable: {
      ft: extendedJoi.number(),
      m : extendedJoi.number(),
      in: extendedJoi.number(),
      cm: extendedJoi.number(),
      mm: extendedJoi.number(),
    },
    connector: {
      xlr  : extendedJoi.number(),
      raymo: extendedJoi.number(),
      bare : extendedJoi.number(),
    },
    baseCost: {
      low : extendedJoi.number(),
      high: extendedJoi.number(),
    },
    breakpoints: {
      mm: extendedJoi.number(),
      cm: extendedJoi.number(),
      m : extendedJoi.number(),
      in: extendedJoi.number(),
      ft: extendedJoi.number(),
    },
    packaging: {
      'Cardboard Box': extendedJoi.number(),
      Pallet         : extendedJoi.number(),
      'Action Packer': extendedJoi.number(),
      Spool          : extendedJoi.number(),
    },
    pex: {
      ft: extendedJoi.number(),
      m : extendedJoi.number(),
      in: extendedJoi.number(),
      cm: extendedJoi.number(),
      mm: extendedJoi.number(),
    },
    pexSand: {
      ft: extendedJoi.number(),
      m : extendedJoi.number(),
      in: extendedJoi.number(),
      cm: extendedJoi.number(),
      mm: extendedJoi.number(),
    },
    flex: {
      ft: extendedJoi.number(),
      m : extendedJoi.number(),
      in: extendedJoi.number(),
      cm: extendedJoi.number(),
      mm: extendedJoi.number(),
    },
    hdpe: {
      ft: extendedJoi.number(),
      m : extendedJoi.number(),
      in: extendedJoi.number(),
      cm: extendedJoi.number(),
      mm: extendedJoi.number(),
    },
    stainless: {
      ft: extendedJoi.number(),
      m : extendedJoi.number(),
      in: extendedJoi.number(),
      cm: extendedJoi.number(),
      mm: extendedJoi.number(),
    },
    pexFittings      : extendedJoi.number(),
    flexFittings     : extendedJoi.number(),
    hdpeFittings     : extendedJoi.number(),
    stainlessFittings: extendedJoi.number(),
    priceModelId     : extendedJoi
      .alternatives()
      .try(
        extendedJoi.string().required(),
        extendedJoi.number().integer().required(),
      ),
    discount: {
      amount: extendedJoi.number(),
      type  : extendedJoi.string(),
      raw   : extendedJoi.string(),
      notes : extendedJoi.string().empty(''),
    },
    additionalCharges: {
      amount: extendedJoi.number(),
      notes : extendedJoi.string().empty(''),
    },
  },

  salesNotes: extendedJoi.string().empty('').allow(null),

  totalPrice : extendedJoi.number(),
  unitPrice  : extendedJoi.number(),
  totalWeight: extendedJoi.number().empty(null).allow(null),

  events: extendedJoi.array().items(cableEvent).allow(null),

  use: extendedJoi.array().items(cableUse).allow(null),

  display: extendedJoi
    .object({
      units: extendedJoi
        .object({
          length: common.LENGTH.required(),
        })
        .required(),
      sort: extendedJoi.number().integer().empty(null).allow(null),
    })
    .required(),

  orderId                : extendedJoi.string().empty('').allow(null),
  erpnextQuotationId     : extendedJoi.string().empty('').allow(null),
  erpnextQuotationItemId : extendedJoi.string().empty('').allow(null),
  itemCode               : extendedJoi.string().empty('').allow(null),
  erpnextSalesOrderId    : extendedJoi.string().empty('').allow(null),
  erpnextSalesOrderItemId: extendedJoi.string().empty('').allow(null),

  description: extendedJoi.string().empty('').allow(null),
})
