import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  productDurationType,
  type default as ProductDurationType,
} from './ProductDurationType.js'
import {
  productServiceType,
  type default as ProductServiceType,
} from './ProductServiceType.js'
import {
  productUnitType,
  type default as ProductUnitType,
} from './ProductUnitType.js'
import { userId, type UserId } from './User.js'

/** Identifier type for public.product_service_ */
export type ProductServiceId = string & { __brand: 'ProductServiceId' }

/** Represents the table public.product_service_ */
export default interface ProductServiceTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<
    ProductServiceId,
    ProductServiceId | undefined,
    ProductServiceId
  >

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.text */
  serviceFullName: ColumnType<string, string, string>

  /** Database type: pg_catalog.text */
  serviceShortName: ColumnType<string, string, string>

  /** Database type: pg_catalog.int4 */
  priceInCents: ColumnType<number, number, number>

  /** Database type: pg_catalog.text */
  currencyType: ColumnType<string, string, string>

  /** Database type: public.product_service_type_ */
  productServiceType: ColumnType<
    ProductServiceType,
    ProductServiceType,
    ProductServiceType
  >

  /** Database type: pg_catalog.text */
  notes: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  skuNumber: ColumnType<string | null, string | null, string | null>

  /** Database type: public.product_unit_type_ */
  productUnitType: ColumnType<
    ProductUnitType | null,
    ProductUnitType | null,
    ProductUnitType | null
  >

  /** Database type: public.product_duration_type_ */
  productDurationType: ColumnType<
    ProductDurationType | null,
    ProductDurationType | null,
    ProductDurationType | null
  >
}

export type ProductService = Selectable<ProductServiceTable>

export type ProductServiceInitializer = Insertable<ProductServiceTable>

export type ProductServiceMutator = Updateable<ProductServiceTable>

export const productServiceId =
  z.string() as unknown as z.Schema<ProductServiceId>

export const productService = z.object({
  id                 : productServiceId,
  createdAt          : z.coerce.date().nullable().optional(),
  createdBy          : userId.nullable().optional(),
  updatedAt          : z.coerce.date().nullable().optional(),
  updatedBy          : userId.nullable().optional(),
  deletedAt          : z.coerce.date().nullable().optional(),
  deletedBy          : userId.nullable().optional(),
  serviceFullName    : z.string(),
  serviceShortName   : z.string(),
  priceInCents       : z.number(),
  currencyType       : z.string(),
  productServiceType : productServiceType,
  notes              : z.string().nullable().optional(),
  skuNumber          : z.string().nullable().optional(),
  productUnitType    : productUnitType.nullable().optional(),
  productDurationType: productDurationType.nullable().optional(),
}) as unknown as z.Schema<ProductService>

export const productServiceInitializer = z.object({
  id                 : productServiceId.optional(),
  createdAt          : z.coerce.date().optional().nullable().optional(),
  createdBy          : userId.optional().nullable().optional(),
  updatedAt          : z.coerce.date().optional().nullable().optional(),
  updatedBy          : userId.optional().nullable().optional(),
  deletedAt          : z.coerce.date().optional().nullable().optional(),
  deletedBy          : userId.optional().nullable().optional(),
  serviceFullName    : z.string(),
  serviceShortName   : z.string(),
  priceInCents       : z.number(),
  currencyType       : z.string(),
  productServiceType : productServiceType,
  notes              : z.string().optional().nullable().optional(),
  skuNumber          : z.string().optional().nullable().optional(),
  productUnitType    : productUnitType.optional().nullable().optional(),
  productDurationType: productDurationType.optional().nullable().optional(),
}) as unknown as z.Schema<ProductServiceInitializer>

export const productServiceMutator = z.object({
  id                 : productServiceId.optional(),
  createdAt          : z.coerce.date().optional().nullable().optional(),
  createdBy          : userId.optional().nullable().optional(),
  updatedAt          : z.coerce.date().optional().nullable().optional(),
  updatedBy          : userId.optional().nullable().optional(),
  deletedAt          : z.coerce.date().optional().nullable().optional(),
  deletedBy          : userId.optional().nullable().optional(),
  serviceFullName    : z.string().optional(),
  serviceShortName   : z.string().optional(),
  priceInCents       : z.number().optional(),
  currencyType       : z.string().optional(),
  productServiceType : productServiceType.optional(),
  notes              : z.string().optional().nullable().optional(),
  skuNumber          : z.string().optional().nullable().optional(),
  productUnitType    : productUnitType.optional().nullable().optional(),
  productDurationType: productDurationType.optional().nullable().optional(),
}) as unknown as z.Schema<ProductServiceMutator>
