import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import { OrderId } from '@beaded/models'

import * as apiUtils from '.'

const url = '/order'

export const get = (id: OrderId, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/${id}`, merge({}, options))

export const getAll = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({}, options))
