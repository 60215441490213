import {
  ServiceContract,
  ServiceContractId,
  Obj,
  ProductService,
  ProductServiceId,
} from '@beaded/models'

import * as apiUtils from '.'

const url = '/service'

//// Stripe Fetch
export const getCheckoutSession = (data: Obj): Promise<Obj> =>
  apiUtils.POST(`${url}/checkout_session`, data)

export const getCheckoutSessionStatus = (session_id: string): Promise<Obj> =>
  apiUtils.GET(`${url}/session_status/${session_id}`)

//// Service Products
export const getAllProductServices = (): Promise<Array<ProductService>> =>
  apiUtils.GET(`${url}/product`)

export const createProductService = (data: Obj) =>
  apiUtils.POST(`${url}/product`, data)

//// Service Contract
export const createServiceContract = (data: Obj) =>
  apiUtils.POST(`${url}/contract`, data)

export const createContractServicePayment = (data: Obj) =>
  apiUtils.POST(`${url}/contract/payment`, data)

export const getAllServiceContracts = (): Promise<Array<ServiceContract>> =>
  apiUtils.GET(`${url}/contract`)

export const updateServiceContract = (id: ServiceContractId, data: Obj) =>
  apiUtils.PUT(`${url}/contract/${id}`, data)

export const updateProductService = (id: ProductServiceId, data: Obj) =>
  apiUtils.PUT(`${url}/product/${id}`, data)

export const removeServiceContract = (id: ServiceContractId) =>
  apiUtils.DELETE(`${url}/contract/${id}`)

export const removeProductService = (id: ProductServiceId) =>
  apiUtils.DELETE(`${url}/product/${id}`)

export const getCurrentProductServicePrices = () =>
  apiUtils.GET(`${url}/product/current_prices`)
