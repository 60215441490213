import merge from 'lodash.merge'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import type { QueryKey, QueryFunction, QueryOptions } from 'react-query'

import { LoggerWithContext, OrganizationId, ProjectId } from '@beaded/models'

import { logger } from 'api'

import { alphabeticalByName } from 'forms/helpers/alphabeticalByField'
import { useAsyncSelectOptions } from 'forms/helpers/useAsyncSelectOptions'
import { SelectInput, SelectInputProps } from 'forms/inputs/SelectInput'

import { useUserContext } from 'hooks/useUserContext'

import { getLastLoggerUse } from 'lib/lastUse'

import { MultiSelectInput, MultiSelectInputProps } from './MultiSelectInput'

type UseLoggerSelectOptionsProps<T = unknown> = {
  filterFn?: (value: T, index?: number, array?: T[]) => boolean
  filter?: Function
  values?: unknown
}

type LoggerOptions = {
  name: string // loggerSerial
  value: number
  organizationId: OrganizationId
  projectId: ProjectId
}

export const useLoggerSelectOptions = (
  props: UseLoggerSelectOptionsProps<LoggerOptions>,
) => {
  const { superUser } = useUserContext()

  const queryArgs: [QueryKey, QueryFunction, QueryOptions?] =
    superUser === 'on'
      ? [
        ['logger', { superUser, projection: 'menu' }],
        () => logger.admin.getMenuProjection(),
      ]
      : [['logger', { projection: 'menu' }], () => logger.getMenuProjection()]

  const { options, ...rest } = useAsyncSelectOptions<LoggerOptions>({
    queryArgs,
    dataKey: 'loggers',
    mapFn  : ({
      currentModem,
      loggerSerial,
      id,
      history,
      ...rest
    }: LoggerWithContext) => {
      const lastUse = getLastLoggerUse(history)

      if (currentModem !== undefined && loggerSerial !== undefined)
        return {
          name:
            currentModem?.imei !== undefined
              ? loggerSerial
              : `${loggerSerial}  * no modem`,
          value : id,
          extras: JSON.stringify({
            id,
            loggerSerial,
            imei: currentModem?.imei,
          }),
          imei          : currentModem?.imei,
          organizationId: lastUse?.organizationId,
          projectId     : lastUse?.projectId,
        }
    },
    sortFn: alphabeticalByName,
  })

  if (props.filterFn) {
    return {
      options: options?.filter(props.filterFn) ?? [],
      ...rest,
    }
  }

  if (props.filter && props.values && options) {
    return {
      options: props.filter(props.values, options),
      ...rest,
    }
  }

  return {
    options: options ?? [],
    ...rest,
  }
}

export const SelectLogger = (props: Omit<SelectInputProps, 'options'>) => {
  const { options } = useLoggerSelectOptions({})

  return (
    <SelectInput
      name={props.name}
      label={props.label}
      options={options}
      placeholder={props.placeholder ?? 'Select a Logger'}
      hookformProps={merge(props.hookformProps, {
        valueAsNumber: false,
        ...props.hookformProps,
      })}
    />
  )
}

export const SelectLoggerWithOrgFilter = (
  props: Omit<SelectInputProps, 'options'> & {
    watchField?: string
  },
) => {
  const { watch } = useFormContext()
  const { options } = useLoggerSelectOptions({})
  const orgId = watch(props.watchField ?? 'orgId')
  const [opts, setOpts] = useState<any[]>([])

  useEffect(() => {
    if (opts.length === 0 && orgId) {
      setOpts(options?.filter((opt: any) => opt.orgId === orgId))
    }
  }, [options, opts.length, orgId])

  return (
    <SelectInput
      name={props.name}
      label={props.label}
      options={opts}
      placeholder={props.placeholder ?? 'Select a Logger'}
      hookformProps={merge(props.hookformProps, {
        valueAsNumber: false,
        disabled     : !orgId,
      })}
    />
  )
}

export const SelectLoggerWithFilter = (
  props: Omit<SelectInputProps, 'options'> & {
    filterFn: (opt: any) => boolean
    watchField: string
  },
) => {
  const { watch } = useFormContext()
  const value = watch(props.watchField)
  const { options } = useLoggerSelectOptions({ filterFn: props.filterFn })

  return (
    <SelectInput
      name={props.name}
      label={props.label}
      options={options}
      placeholder={
        options.length === 0
          ? 'No loggers found for this project'
          : props.placeholder ?? 'Select a Logger'
      }
      hookformProps={merge(props.hookformProps, {
        valueAsNumber: false,
        disabled     : !value || options.length === 0,
      })}
    />
  )
}

export const SelectLoggerWithProjectFilter = (
  props: Omit<SelectInputProps, 'options'> & {
    watchField?: string
  },
) => {
  const { watch } = useFormContext()
  const projectId = watch(props.watchField ?? 'project.id')
  const { options } = useLoggerSelectOptions({
    filterFn: (opt: any) => Boolean(projectId) && opt.projectId === projectId,
  })

  return (
    <SelectInput
      name={props.name}
      label={props.label}
      options={options}
      placeholder={
        options.length === 0
          ? 'No loggers found for this project'
          : props.placeholder ?? 'Select a Logger'
      }
      hookformProps={merge(props.hookformProps, {
        valueAsNumber: false,
        disabled     : !projectId || options.length === 0,
      })}
    />
  )
}

export const MultiSelectLoggerWithFilter = (
  props: Omit<MultiSelectInputProps, 'options'> & {
    filterFn: (opt: any) => boolean
    watchField: string
  },
) => {
  const { watch } = useFormContext()
  const projectId = watch('projectId')
  const { options } = useLoggerSelectOptions({ filterFn: props.filterFn })

  return (
    <MultiSelectInput
      name={props.name}
      label={props.label}
      options={options}
      placeholder={
        options.length === 0
          ? 'No loggers found for this project'
          : props.placeholder ?? 'Select a Logger'
      }
      maxSelections={props.maxSelections}
      hookformProps={merge(props.hookformProps, {
        valueAsNumber: false,
        disabled     : !projectId || options.length === 0,
      })}
      resetOnFieldChange={props.resetOnFieldChange}
    />
  )
}
