import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

/** Identifier type for public.organization_ */
export type OrganizationId = number & { __brand: 'OrganizationId' }

/** Represents the table public.organization_ */
export default interface OrganizationTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<OrganizationId, OrganizationId | undefined, OrganizationId>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  organizationName: ColumnType<string, string, string>

  /** Database type: pg_catalog.text */
  notes: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.int4 */
  copperId: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.text */
  copperUrl: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  isFreeAccount: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.text */
  erpnextCustomerId: ColumnType<string | null, string | null, string | null>
}

export type Organization = Selectable<OrganizationTable>

export type OrganizationInitializer = Insertable<OrganizationTable>

export type OrganizationMutator = Updateable<OrganizationTable>

export const organizationId = z.number() as unknown as z.Schema<OrganizationId>

export const organization = z.object({
  id               : organizationId,
  createdAt        : z.coerce.date().nullable().optional(),
  createdBy        : z.string().nullable().optional(),
  updatedAt        : z.coerce.date().nullable().optional(),
  updatedBy        : z.string().nullable().optional(),
  deletedAt        : z.coerce.date().nullable().optional(),
  deletedBy        : z.string().nullable().optional(),
  organizationName : z.string(),
  notes            : z.string().nullable().optional(),
  copperId         : z.number().nullable().optional(),
  copperUrl        : z.string().nullable().optional(),
  isFreeAccount    : z.boolean().nullable().optional(),
  erpnextCustomerId: z.string().nullable().optional(),
}) as unknown as z.Schema<Organization>

export const organizationInitializer = z.object({
  id               : organizationId.optional(),
  createdAt        : z.coerce.date().optional().nullable().optional(),
  createdBy        : z.string().optional().nullable().optional(),
  updatedAt        : z.coerce.date().optional().nullable().optional(),
  updatedBy        : z.string().optional().nullable().optional(),
  deletedAt        : z.coerce.date().optional().nullable().optional(),
  deletedBy        : z.string().optional().nullable().optional(),
  organizationName : z.string(),
  notes            : z.string().optional().nullable().optional(),
  copperId         : z.number().optional().nullable().optional(),
  copperUrl        : z.string().optional().nullable().optional(),
  isFreeAccount    : z.boolean().optional().nullable().optional(),
  erpnextCustomerId: z.string().optional().nullable().optional(),
}) as unknown as z.Schema<OrganizationInitializer>

export const organizationMutator = z.object({
  id               : organizationId.optional(),
  createdAt        : z.coerce.date().optional().nullable().optional(),
  createdBy        : z.string().optional().nullable().optional(),
  updatedAt        : z.coerce.date().optional().nullable().optional(),
  updatedBy        : z.string().optional().nullable().optional(),
  deletedAt        : z.coerce.date().optional().nullable().optional(),
  deletedBy        : z.string().optional().nullable().optional(),
  organizationName : z.string().optional(),
  notes            : z.string().optional().nullable().optional(),
  copperId         : z.number().optional().nullable().optional(),
  copperUrl        : z.string().optional().nullable().optional(),
  isFreeAccount    : z.boolean().optional().nullable().optional(),
  erpnextCustomerId: z.string().optional().nullable().optional(),
}) as unknown as z.Schema<OrganizationMutator>
