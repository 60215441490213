import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  InputGroup,
  HStack,
  FormErrorMessage,
  Checkbox,
  Spacer,
  VStack,
  Text,
  Center,
  Box,
  chakra,
  Tooltip,
  IconButton,
  InputRightAddon,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { debounce } from 'lodash'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import React from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { TbBarcode, TbBarcodeOff } from 'react-icons/tb'
import { useMutation } from 'react-query'

import {
  LoggerModel,
  LoggerModels,
  LoggerWithContext,
  loggerWithContextMutator,
  LoggerWithContextMutator,
} from '@beaded/models'

import { logger, modem } from 'api'

import d505 from 'assets/d505.png'
import d605 from 'assets/d605.png'
import spot from 'assets/spot.png'

import { ModalContainer } from 'containers/ModalContainer'

import {
  ImeiScanner,
  shouldShowImeiStuff,
} from 'forms/helpers/loggerFormSharedHelpers'
import { DefaultInput } from 'forms/inputs/DefaultInput'
import { SelectInput } from 'forms/inputs/SelectInput'
import { TextareaInput } from 'forms/inputs/TextAreaInput'

import { useOS } from 'hooks/useOS'

import { logValidationErrors } from 'lib/logValidationErrors'
import { formatLoggerSerial, parseLoggerSerial } from 'lib/loggerSerial'
import { queryClient } from 'lib/react-query'

import { KeysOf } from 'types/common'

type UpdateLoggerFormInput = LoggerWithContextMutator & {
  serial: {
    id: number
    model: LoggerModel
  }
}

type UpdateLoggerProps = {
  data: LoggerWithContext
  iconButton?: React.ReactElement
  iconButtonProps?: {}
}
export const UpdateLogger = (props: UpdateLoggerProps) => {
  const [enableScanner, setEnableScanner] = useState(false)

  const methods = useForm<UpdateLoggerFormInput>({
    defaultValues: props.data,
    resolver     : zodResolver(loggerWithContextMutator),
  })

  const mutation = useMutation(
    async (data: UpdateLoggerFormInput) => {
      const validationResult = loggerWithContextMutator.safeParse(data)

      if (validationResult.success === false) {
        logValidationErrors(validationResult.error)
        return { error: validationResult.error }
      }

      const result = await logger.update(props.data.id, validationResult.data)

      if (result?.error) throw result.error

      return result
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('loggers')
      },
    },
  )

  const handler = (data: UpdateLoggerFormInput) => {
    mutation.mutateAsync(data)
  }

  const model = methods.watch('serial.model')

  return (
    <ModalContainer
      TriggerButton={({ onClick }: any) =>
        props.iconButton ? (
          <Tooltip label='Update Logger'>
            <IconButton
              icon={props.iconButton}
              aria-label='Update Logger'
              {...props.iconButtonProps}
              onClick={onClick}
            />
          </Tooltip>
        ) : (
          <Button onClick={onClick}>Update Logger</Button>
        )
      }
      modalProps={{ size: '3xl' }}
      header='Update Logger'
      footer={() => (
        <VStack justifyContent='right' align='right' width='100%'>
          <HStack>
            <Spacer />
            {mutation.isError && (
              <pre>
                <chakra.code color='red.400'>
                  {JSON.stringify(mutation.error, null, 2)}
                </chakra.code>
              </pre>
            )}
            {mutation.isSuccess && <CheckCircleIcon color='green.400' />}
            {mutation.isError && <WarningIcon color='red.400' />}
            <Button
              onClick={methods.handleSubmit(handler)}
              isLoading={mutation.isLoading}
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      )}
      onClose={methods.reset}
    >
      <FormProvider {...methods}>
        <form>
          <Grid templateColumns='repeat(6, 1fr)' gap={3}>
            <GridItem colSpan={3}>
              <SerialInputGroup />
            </GridItem>

            <GridItem colSpan={3}>
              {shouldShowImeiStuff(model) && (
                <ImeiInput
                  enableScanner={enableScanner}
                  setEnableScanner={setEnableScanner}
                />
              )}
            </GridItem>

            <GridItem colSpan={6}>
              {shouldShowImeiStuff(model) && enableScanner && (
                <ImeiScanner
                  setEnableScanner={setEnableScanner}
                  enableScanner={enableScanner}
                  fieldName='newModem.imei'
                  dateField='newModem.startedAt'
                />
              )}
            </GridItem>

            <GridItem colSpan={2}>
              <SelectInput
                name='chipset'
                label='Chipset'
                options={['nordic', 'cypress', 'other']}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <DefaultInput name='firmwareVersion' label='Firmware' />
            </GridItem>

            <GridItem colSpan={2}>
              <DefaultInput name='deviceType' label='Device Type' />
            </GridItem>

            <GridItem colSpan={6} pt={6}>
              {model !== 'NGT' ? (
                <PortsInput />
              ) : (
                <Text fontWeight={500}>
                  No image for the NGT logger family (Ethernet Activator)
                </Text>
              )}
            </GridItem>

            <GridItem colSpan={6}>
              <TextareaInput name='notes' label='Notes' />
            </GridItem>
          </Grid>
        </form>
      </FormProvider>
    </ModalContainer>
  )
}

interface SerialInputGroupProps {}

type SerialValidity =
  | {
      valid: boolean
      message?: string
    }
  | null
  | undefined

// Input Pair
const SerialInputGroup = (props: SerialInputGroupProps) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()
  const loggerSerial = watch('loggerSerial')
  const model = watch('serial.model')
  const id = watch('serial.id')

  const [validity, setValidity] = useState<SerialValidity>(null)

  useEffect(() => {
    const { model, id } = parseLoggerSerial(watch('loggerSerial'))
    setValue('serial.model', model)
    setValue('serial.id', id)
  }, [])

  useEffect(() => {
    const formattedSerial = formatLoggerSerial({ model, id })
    // if (formattedSerial !== loggerSerial) {
    const validateSerial = debounce(async () => {
      setValue('loggerSerial', formattedSerial)
      const validationResult = await logger.validateSerial(formattedSerial)
      setValidity(validationResult)
    }, 500)

    if (formattedSerial !== loggerSerial && model && id && id > 100)
      validateSerial()
    // }
  }, [model, id, setValue, loggerSerial])

  return (
    <>
      <FormControl isRequired={true} isInvalid={validity?.valid === false}>
        <HStack>
          <FormLabel htmlFor='serial.model'>Model</FormLabel>

          <FormLabel
            htmlFor='serial.id'
            style={{ marginBottom: '8px', marginLeft: '80px' }}
          >
            Serial
          </FormLabel>
        </HStack>
        <InputGroup>
          <Select
            borderRight='none'
            borderEndEndRadius={0}
            borderStartEndRadius={0}
            {...register('serial.model', {
              required: true,
            })}
            autoComplete='off'
          >
            {LoggerModels.map((model) => (
              <option key={model} value={model}>
                {model}
              </option>
            ))}
          </Select>
          <Input
            borderLeft='none'
            borderEndStartRadius={0}
            borderStartStartRadius={0}
            autoComplete='off'
            {...register('serial.id', {
              required     : true,
              valueAsNumber: true,
              validate     : async (id: number) => {
                if (validity?.valid === false) return validity?.message
                return true
              },
            })}
            type='number'
          />
        </InputGroup>
        <FormErrorMessage>
          {validity?.valid === false
            ? validity?.message ?? 'There was an error'
            : ''}
        </FormErrorMessage>
      </FormControl>
    </>
  )
}

type ImeiInputProps = {
  enableScanner: boolean
  setEnableScanner: (value: boolean) => void
}

const ImeiInput = (props: ImeiInputProps) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const [validity, setValidity] = useState<SerialValidity>(null)

  console.log(errors)
  const currentImei = watch('currentModem.imei')
  const imei = watch('newModem.imei')
  const reuseImei = watch('reuseImei')
  const model = watch('serial.model')

  useEffect(() => {
    const validateImei = debounce(async () => {
      const validationResult = await modem.validateImei(imei)
      setValidity(validationResult)
    }, 500)

    if (imei && imei.length === 15 && imei !== currentImei) validateImei()
  }, [currentImei, imei])

  return (
    <FormControl
      isInvalid={validity?.valid === false && !reuseImei}
      isRequired={shouldShowImeiStuff(model)}
    >
      <FormLabel htmlFor='newModem.imei'>IMEI</FormLabel>
      <InputGroup>
        <Input
          autoComplete='off'
          {...register('newModem.imei', {
            maxLength: 15,
            minLength: 15,
            validate : async (imei: string) => {
              if (imei.length !== 15) return 'IMEI must be 15 digits'
              if (validity?.valid === false && !reuseImei)
                return validity?.message
              return true
            },
            value: currentImei ?? imei,
          })}
          required={shouldShowImeiStuff(model)}
        />
        <InputRightAddon>
          {/* icons for enable/disable barcode */}
          <IconButton
            aria-label='barcode'
            icon={props.enableScanner ? <TbBarcodeOff /> : <TbBarcode />}
            onClick={() => props.setEnableScanner(!props.enableScanner)}
          />
        </InputRightAddon>
        {validity?.valid === false &&
        validity?.message?.startsWith('Modem is currently in use') ? (
            <Checkbox pl={2} {...register('reuseImei')}>
            Reuse
            </Checkbox>
          ) : null}
      </InputGroup>
      <FormErrorMessage>
        {validity?.valid === false
          ? validity?.message ?? 'There was an error'
          : ''}
      </FormErrorMessage>
    </FormControl>
  )
}

const getNaturalPoint = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
  const { clientX, clientY } = e
  const target = e.target as HTMLImageElement
  const { naturalWidth, naturalHeight, width, height } = target
  const { top, left } = target.getBoundingClientRect()

  const result = {
    x: ((clientX - left - 8) * naturalWidth) / width,
    y: ((clientY - top - 8) * naturalHeight) / height,
  }

  return result
}

const PortsInput = () => {
  const os = useOS()

  const calculatePosition = ({ x, y }: { x: number; y: number }) => {
    return {
      top:
        (y * (imgElem?.current?.height ?? 1)) /
          (imgElem?.current?.naturalHeight ?? 1) -
        5,
      left:
        (x * (imgElem?.current?.width ?? 1)) /
          (imgElem?.current?.naturalWidth ?? 1) -
        5,
    }
  }

  const onImageLoad = () => {
    if (
      imgElem.current &&
      imgElem.current !== null &&
      imgElem.current.width > 0
    ) {
      setPoints(
        Object.entries(ports[(model ?? 'D605') as KeysOf<typeof ports>]).map(
          (port) => ({
            label: port[0],
            x    : port[1].x,
            y    : port[1].y,
          }),
        ),
      )
    }
  }

  const imgElem = React.useRef<HTMLImageElement>(null)
  const onClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    const { ctrlKey, metaKey } = e

    if ((os === 'macos' && metaKey) || ctrlKey) {
      const naturalPoint = getNaturalPoint(e)
      console.info(naturalPoint)
    }
  }

  const [point1, setPoint1] = useState<{
    x: number
    y: number
    rect: DOMRect
  } | null>(null)
  const [point2, setPoint2] = useState<{
    x: number
    y: number
    rect: DOMRect
  } | null>(null)
  const [point3, setPoint3] = useState<{
    x: number
    y: number
    rect: DOMRect
  } | null>(null)
  const [point4, setPoint4] = useState<{
    x: number
    y: number
    rect: DOMRect
  } | null>(null)
  const [point5, setPoint5] = useState<{
    x: number
    y: number
    rect: DOMRect
  } | null>(null)

  const updateRef = useCallback((node) => {
    if (node !== null) {
      if (node.textContent === '1') {
        setPoint1({
          x   : node.getBoundingClientRect().x,
          y   : node.getBoundingClientRect().y,
          rect: node.getBoundingClientRect(),
        })
      }
      if (node.textContent === '2') {
        setPoint2({
          x   : node.getBoundingClientRect().x,
          y   : node.getBoundingClientRect().y,
          rect: node.getBoundingClientRect(),
        })
      }
      if (node.textContent === '3') {
        setPoint3({
          x   : node.getBoundingClientRect().x,
          y   : node.getBoundingClientRect().y,
          rect: node.getBoundingClientRect(),
        })
      }
      if (node.textContent === '4') {
        setPoint4({
          x   : node.getBoundingClientRect().x,
          y   : node.getBoundingClientRect().y,
          rect: node.getBoundingClientRect(),
        })
      }
      if (node.textContent === '5') {
        setPoint5({
          x   : node.getBoundingClientRect().x,
          y   : node.getBoundingClientRect().y,
          rect: node.getBoundingClientRect(),
        })
      }
    }
  }, [])

  const [points, setPoints] = useState<
    { label: string; x: number; y: number }[]
  >([])

  const { watch, setValue } = useFormContext()
  const model = watch('serial.model')

  let image = d605
  if (model === 'D605') image = d605
  if (model === 'D505') image = d505
  if (model === 'DLB') image = d505
  if (model === 'SL') image = spot

  if (model === 'NGT') return null

  return (
    <Grid templateColumns='1fr 1fr 1fr'>
      <GridItem colSpan={3} pb={6}>
        <HStack>
          <Text fontWeight='bold' whiteSpace='nowrap'>
            Set All:
          </Text>
          <Text fontWeight='500' whiteSpace='nowrap'>
            Port
          </Text>
          <Select
            onChange={(e) => {
              setValue('port1Type', e.target.value)
              setValue('port2Type', e.target.value)
              setValue('port3Type', e.target.value)
              setValue('port4Type', e.target.value)
              setValue('port5Type', e.target.value)
            }}
          >
            {typeOptions.map(({ name, value, disabled }) => (
              <option
                key={`set-all-type-${name}`}
                value={value}
                disabled={disabled}
              >
                {name}
              </option>
            ))}
          </Select>
          <Text fontWeight='500' whiteSpace='nowrap'>
            Function
          </Text>
          <Select
            onChange={(e) => {
              setValue('port1Function', e.target.value)
              setValue('port2Function', e.target.value)
              setValue('port3Function', e.target.value)
              setValue('port4Function', e.target.value)
              setValue('port5Function', e.target.value)
            }}
          >
            {functionOptions.map(({ name, value, disabled }) => (
              <option
                key={`set-all-func-${name}`}
                value={value}
                disabled={disabled}
              >
                {name}
              </option>
            ))}
          </Select>
        </HStack>
      </GridItem>
      <GridItem>
        {model === 'D605' ? (
          <SinglePortInput
            x={point4?.x}
            y={point4?.y}
            position='top-left'
            pointRect={point4?.rect}
            label='Port 4'
            name='port4'
          />
        ) : null}
        {model === 'D505' || model === 'DLB' ? (
          <SinglePortInput
            x={point2?.x}
            y={point2?.y}
            position='top-left'
            pointRect={point2?.rect}
            label='Port 2'
            name='port2'
          />
        ) : null}
      </GridItem>
      <GridItem rowSpan={2}>
        <Center>
          <Box style={{ position: 'relative' }} width='fit-content'>
            <img
              src={image}
              width='300px'
              onClick={onClick}
              ref={imgElem}
              alt={`logger imag for ${model}`}
              onLoad={onImageLoad}
              style={{ zIndex: -1 }}
            />
            {points.map((point, idx) => {
              return (
                <Box
                  key={JSON.stringify(point)}
                  position='absolute'
                  cursor='pointer'
                  borderRadius='50%'
                  bgColor='#07559B'
                  color='white'
                  fontSize='12px'
                  fontWeight={600}
                  width='20px'
                  height='20px'
                  ref={updateRef}
                  {...calculatePosition(point)}
                >
                  <Center>{point.label}</Center>
                </Box>
              )
            })}
          </Box>
        </Center>
      </GridItem>
      <GridItem>
        {model === 'D605' ? (
          <SinglePortInput
            x={point1?.x}
            y={point1?.y}
            position='top-right'
            pointRect={point1?.rect}
            label='Port 1'
            name='port1'
          />
        ) : null}
        {model === 'D505' || model === 'DLB' ? (
          <SinglePortInput
            x={point3?.x}
            y={point3?.y}
            position='top-right'
            pointRect={point3?.rect}
            label='Port 3'
            name='port3'
          />
        ) : null}
      </GridItem>
      <GridItem>
        {model === 'D605' ? (
          <SinglePortInput
            x={point3?.x}
            y={point3?.y}
            position='bottom-left'
            pointRect={point3?.rect}
            label='Port 3'
            name='port3'
          />
        ) : null}
        {model === 'D505' || model === 'DLB' ? (
          <SinglePortInput
            x={point1?.x}
            y={point1?.y}
            position='bottom-left'
            pointRect={point1?.rect}
            label='Port 1'
            name='port1'
          />
        ) : null}
      </GridItem>
      <GridItem>
        {model === 'D605' ? (
          <SinglePortInput
            x={point2?.x}
            y={point2?.y}
            position='bottom-right'
            pointRect={point2?.rect}
            label='Port 2'
            name='port2'
          />
        ) : null}
        {model === 'D505' || model === 'DLB' ? (
          <SinglePortInput
            x={point4?.x}
            y={point4?.y}
            position='bottom-right'
            pointRect={point4?.rect}
            label='Port 4'
            name='port4'
          />
        ) : null}
        {model === 'SL' ? (
          <SinglePortInput
            x={point1?.x}
            y={point1?.y}
            position='bottom-right'
            pointRect={point1?.rect}
            label='Port 1'
            name='port1'
          />
        ) : null}
      </GridItem>
      <GridItem colSpan={2} />
      <GridItem>
        {model === 'D505' || model === 'DLB' ? (
          <SinglePortInput
            x={point5?.x}
            y={point5?.y}
            position='extra-port'
            pointRect={point5?.rect}
            label='Extra'
            name='port5'
          />
        ) : null}
      </GridItem>
    </Grid>
  )
}

const ports = {
  D605: {
    1: { x: 730.796052631579, y: 251.6881188118812 },
    2: { x: 714.8223684210526, y: 519.3564356435644 },
    3: { x: 471.2236842105263, y: 523.3514851485148 },
    4: { x: 459.24342105263156, y: 263.6732673267327 },
  } as const,
  SL: {
    1: { x: 2775.5098684210525, y: 2259.2236842105262 },
  } as const,
  D505: {
    1: { x: 1020.2467105263158, y: 2621.1381578947367 },
    2: { x: 1338.3881578947369, y: 2621.1381578947367 },
    3: { x: 1623.6184210526317, y: 2621.1381578947367 },
    4: { x: 1941.7598684210527, y: 2621.1381578947367 },
    5: { x: 2686.2332589285716, y: 2285.073891625616 },
  } as const,
  DLB: {
    1: { x: 1020.2467105263158, y: 2621.1381578947367 },
    2: { x: 1338.3881578947369, y: 2621.1381578947367 },
    3: { x: 1623.6184210526317, y: 2621.1381578947367 },
    4: { x: 1941.7598684210527, y: 2621.1381578947367 },
    5: { x: 2686.2332589285716, y: 2285.073891625616 },
  } as const,
} as const

type SinglePortInputProps = {
  x?: number
  y?: number
  position?:
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'extra-port'
  pointRect?: DOMRect
  label: string
  name: string
}

type Option = {
  name: string
  value: string
  disabled?: boolean
}

const typeOptions: Option[] = [
  { name: 'RAYMO', value: 'RAYMO' },
  { name: 'XLR', value: 'XLR' },
  { name: 'PWR', value: 'PWR' },
]

const functionOptions: Option[] = [
  { name: 'DTC', value: 'DTC' },
  { name: 'SONIC', value: 'SONIC' },
  { name: 'UART', value: 'UART' },
  { name: 'CHARGER', value: 'CHARGER' },
]
const SinglePortInput = forwardRef((props: SinglePortInputProps, imgRef) => {
  const inputRef = React.useRef<HTMLDivElement>(null)
  const { register, watch } = useFormContext()
  const model = watch('serial.model')

  const [linePosition, setLinePosition] = useState<{
    x1: number
    y1: number
    x2: number
    y2: number
    top: number
    left: number
    width: number
    height: number
  }>({
    x1    : 0,
    y1    : 0,
    x2    : 0,
    y2    : 0,
    top   : 0,
    left  : 0,
    width : 0,
    height: 0,
  })

  useEffect(() => {
    const rect = inputRef.current?.getBoundingClientRect()
    const pointRect = props.pointRect //elem?.getBoundingClientRect()
    const trueX = (props.x ?? 0) + ((pointRect?.width ?? 0) - 5) / 2
    const trueY = (props.y ?? 0) + ((pointRect?.height ?? 0) - 5) / 2

    let x1 = 0,
      y1 = 0,
      x2 = 0,
      y2 = 0,
      top = 0,
      left = 0,
      width = 0,
      height = 0

    if (props.position === 'top-left') {
      // box stretches from input element at right to target at left

      x1 = rect?.width ?? 0
      y1 = (rect?.height ?? 0) / 2

      x2 = trueX - (rect?.x ?? 0)
      y2 = trueY - (rect?.y ?? 0)

      top = y1 - 2
      left = x1 - 2

      width = x2 - x1
      height = y2 - y1
    }
    if (props.position === 'top-right') {
      // box stretches from input element at right to target at left

      x2 = rect?.left ?? 0
      y2 = (rect?.top ?? 0) + (rect?.height ?? 0) / 2

      x1 = trueX
      y1 = trueY

      width = x2 - x1
      height = y1 - y2

      top = -2 + (rect?.height ?? 0) / 2
      left = -width
    }
    if (props.position === 'bottom-left') {
      // box stretches from input element at right to target at left

      x1 = rect?.width ?? 0
      y1 = (rect?.height ?? 0) / 2

      x2 = trueX - (rect?.x ?? 0)
      y2 = trueY - (rect?.y ?? 0)

      top = (rect?.height ?? 0) / 2
      left = x1 - 2

      width = x2 - x1
      height = y2 - y1 + 2

      if (model === 'D605') {
        top = y2
      }

      if (height < 0) {
        height = -height + 2
        y2 = y1
        y1 = y2 + height + 2
      }
    }
    if (props.position === 'bottom-right') {
      // box stretches from input element at right to target at left

      x2 = rect?.left ?? 0
      y2 = (rect?.top ?? 0) + (rect?.height ?? 0) / 2

      x1 = trueX
      y1 = trueY

      width = x2 - x1
      height = y1 - y2

      if (height < 0) {
        height = -height + 2
        y2 = y1
        y1 = y2 + height + 2
      }

      top = (rect?.height ?? 0) / 2
      left = -width + 2
    }

    if (props.position === 'extra-port') {
      // box stretches from input element at bottom right to target at left

      x2 = rect?.left ?? 0
      y2 = (rect?.top ?? 0) + (rect?.height ?? 0) / 2

      x1 = trueX
      y1 = trueY

      width = x2 - x1
      height = y2 - y1

      top = -height + 2 + (rect?.height ?? 0) / 2
      left = -width + 2
    }

    setLinePosition({ x1, y1, x2, y2, top, left, width, height })
  }, [inputRef, model, props.pointRect, props.position, props.x, props.y])

  return (
    <>
      <Box position='relative'>
        <GridItem ref={inputRef}>
          <Grid
            templateColumns='auto 1fr 1fr'
            border='1px solid rgb(26, 32, 44)'
            borderRadius={3}
            p={1}
          >
            <GridItem />
            <GridItem textAlign='center'>
              <Text>Type</Text>
            </GridItem>
            <GridItem textAlign='center'>
              <Text>Function</Text>
            </GridItem>
            <GridItem
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              {/* <Text>{props.label}&nbsp;</Text> */}
            </GridItem>
            <GridItem>
              <Select size='sm' {...register(`${props.name}Type`)}>
                {typeOptions.map(({ name, value, disabled }) => (
                  <option key={`${name}`} value={value} disabled={disabled}>
                    {name}
                  </option>
                ))}
              </Select>
            </GridItem>
            <GridItem>
              <Select size='sm' {...register(`${props.name}Function`)}>
                {functionOptions.map(({ name, value, disabled }) => (
                  <option key={`${name}`} value={value} disabled={disabled}>
                    {name}
                  </option>
                ))}
              </Select>
            </GridItem>
          </Grid>
        </GridItem>

        <svg
          width={`${linePosition.width + 5}px`}
          height={`${linePosition.height + 5}px`}
          style={{
            position: 'absolute',
            ...(props.position === 'top-left' && {
              top : linePosition.top,
              left: linePosition.left,
            }),
            ...(props.position === 'top-right' && {
              top : `${linePosition.top}px`,
              left: `${linePosition.left}px`,
            }),
            ...(props.position === 'bottom-left' && {
              top : linePosition.top,
              left: linePosition.left,
            }),
            ...(props.position === 'bottom-right' && {
              top : linePosition.top,
              left: linePosition.left,
            }),
            ...(props.position === 'extra-port' && {
              top : linePosition.top,
              left: linePosition.left,
            }),
            zIndex: 1500,
          }}
        >
          <path
            d={getPath({
              position: props.position,
              linePosition,
              model,
            })}
            stroke='black'
            strokeWidth='6'
            fill='transparent'
          />
          <path
            d={getPath({
              position: props.position,
              linePosition,
              model,
            })}
            stroke='#07559B'
            strokeWidth='3'
            fill='transparent'
          />
        </svg>
      </Box>
    </>
  )
})

const getPath = ({
  position,
  linePosition,
  model,
}: {
  position?: string
  linePosition: any
  model: string
}) => {
  switch (position) {
    case 'top-left':
      return `M ${2} ${2} C ${2 + linePosition.width / 8} ${2 + linePosition.height / 8}, ${2 + linePosition.width - linePosition.width / 8} ${2}, ${linePosition.width - 2} ${linePosition.height - 2}`

    case 'top-right':
      return `M ${2} ${linePosition.height - 2} C ${2 + linePosition.width / 8} ${2 + linePosition.height / 8}, ${2 + linePosition.width - linePosition.width / 8} ${2}, ${linePosition.width - 2} ${2}`

    case 'bottom-left':
      if (model === 'D605')
        return `M ${2} ${linePosition.height - 2} C ${linePosition.width / 8} ${linePosition.height - linePosition.height / 8 - 2}, ${linePosition.width - linePosition.width / 8 - 2} ${linePosition.height - linePosition.height / 8 - 2}, ${linePosition.width - 2} ${2}`
      else
        return `M ${2} ${2} C ${linePosition.width / 8} ${2 + linePosition.height / 8}, ${2 + linePosition.width - linePosition.width / 8} ${2}, ${linePosition.width - 2} ${linePosition.height - 2}`

    case 'bottom-right':
      if (model === 'D605')
        return `M ${linePosition.width - 2} ${linePosition.height - 2} C ${linePosition.width / 8 - 2} ${linePosition.height - 2}, ${linePosition.height - 2} ${2}, ${2} ${2}`
      return `M ${2} ${linePosition.height - 2} C ${2 + linePosition.width / 8} ${2 + linePosition.height / 8}, ${2 + linePosition.width - linePosition.width / 8} ${2}, ${linePosition.width - 2} ${2}`

    case 'extra-port':
      return `M ${linePosition.width - 2} ${linePosition.height - 2} C ${2 + linePosition.width / 8} ${linePosition.height - 2}, ${2 + linePosition.width / 8} ${2 + linePosition.height / 8}, ${4} ${7}`

    default:
      return ''
  }
}
