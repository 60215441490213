import { AnyZodObject, z } from 'zod'

import {
  ServiceContract,
  ServiceContractPayment,
  ServiceContractInitializer,
  serviceContractInitializer,
  ServiceContractPaymentId,
  EmbeddedLoggerLastTransmission,
  ServiceContractItemInitializer,
  ServiceContractItem,
  ServiceContractItemId,
  productServiceHistoryId,
} from '@beaded/models'

export type ExtendedServiceContractInitializer = ServiceContractInitializer & {
  items: Array<Omit<ServiceContractItemInitializer, 'serviceContractId'>>
}

export type ExtendedServiceContractPayment = Array<
  Omit<
    ServiceContractPayment,
    | 'id'
    | 'notes'
    | 'createdAt'
    | 'createdBy'
    | 'updatedAt'
    | 'updatedBy'
    | 'deletedAt'
    | 'deletedBy'
  > & {
    paymentId: ServiceContractPaymentId
    paymentNotes: string | null
  }
>

export type ExtendedServiceContract = Omit<
  ServiceContract,
  'deletedAt' | 'deletedBy' | 'createdBy' | 'updatedBy'
> & {
  projectName: string | null
  createdByUser: string | null
  updatedByUser: string | null
  organizationName: string | null
  items: ExtendedServiceContractItem[]
  contractPayments: ExtendedServiceContractPayment

  lastTransmission: EmbeddedLoggerLastTransmission

  totalDueInCents: number | null
}

export type ExtendedServiceContractItem = Omit<
  ServiceContractItem,
  | 'deletedAt'
  | 'deletedBy'
  | 'createdBy'
  | 'createdAt'
  | 'updatedBy'
  | 'updatedAt'
  | 'id'
  | 'serviceContractItemNotes'
  | 'notes'
> & {
  serviceContractItemId: ServiceContractItemId
  serviceContractItemNotes: string
}

// export type ExtendedServiceContractItem = Array<
//   Omit<
//     Subcontract,
//     | 'id'
//     | 'notes'
//     | 'createdAt'
//     | 'createdBy'
//     | 'updatedAt'
//     | 'updatedBy'
//     | 'deletedAt'
//     | 'deletedBy'
//   > & {
//     subcontractId: SubcontractId
//     subcontractNotes: string | null
//     services?: Array<
//       Omit<
//         SubcontractService,
//         | 'id'
//         | 'createdAt'
//         | 'createdBy'
//         | 'updatedAt'
//         | 'updatedBy'
//         | 'deletedAt'
//         | 'deletedBy'
//       > & {
//         serviceId: SubcontractServiceId
//       }
//     >
//   }
// >

// type SubcontractService = {
//   productServiceId: string
//   serviceShortName: string
//   serviceQuantity: number | null
// }

// export type ExtendedServiceContractInitializer = {
//   serviceContract: {
//     subcontracts: Array<{
//       serviceEndDate: Date | null
//       trialPeriodEnd: Date | null
//       serviceStartDate: Date | null
//       trialPeriodStart: Date | null
//       totalPriceInCents: number
//       notes: string | null
//       services: Array<{
//         productServiceId: string
//         serviceShortName: string
//         serviceQuantity: number | null
//       }>
//     }>
//     projectId: number
//     serviceExpiredAt: Date | null
//     contractNumber: number | null
//     totalPriceInCents: number
//     notes: string | null
//     rental: boolean
//   }
// }

export const extendedContractInitializer = (
  serviceContractInitializer as unknown as AnyZodObject
).merge(
  z.object({
    invoiceDate: z
      .preprocess((v, ctx) => {
        if (!v || v === '') {
          return undefined
        }

        return v
      }, z.date().optional().nullable().optional())
      .optional()
      .nullable(),
    items: z.array(
      z.object({
        notes            : z.string().nullable(),
        serviceEndDate   : z.coerce.date(),
        serviceStartDate : z.coerce.date(),
        serviceExpiredAt : z.coerce.date(),
        totalPriceInCents: z.number().int(),

        trialPeriodEnd: z
          .preprocess((v, ctx) => {
            if (!v || v === '') {
              return undefined
            }

            return v
          }, z.date().optional().nullable().optional())
          .optional()
          .nullable(),
        trialPeriodStart: z
          .preprocess((v, ctx) => {
            if (!v) {
              return undefined
            }

            return v
          }, z.date().optional().nullable().optional())
          .optional()
          .nullable(),

        productServiceHistoryId: productServiceHistoryId,
        serviceName            : z.string(),
        servicePriceInCents    : z.number(),
        serviceQuantity        : z.number().int().nullable().optional(),
        serviceDuration        : z.number().int().nullable().optional(),
        serviceDurationType    : z.string().nullable().optional(),
        serviceUnitType        : z.string().nullable().optional(),
      }),
    ),
  }),
) as unknown as z.Schema<ExtendedServiceContractInitializer>

// export const extendedContractInitializer = z.object({
//   serviceContract: z.object({
//     subcontracts: z.array(
//       z.object({
//         serviceStartDate: z.coerce.date().nullable(),
//         serviceEndDate  : z.coerce.date().nullable(),
//         trialPeriodStart: z
//           .preprocess((v, ctx) => {
//             if (!v) {
//               return undefined
//             }

//             return v
//           }, z.date().optional().nullable().optional())
//           .optional()
//           .nullable(),
//         trialPeriodEnd: z
//           .preprocess((v, ctx) => {
//             if (!v) {
//               return undefined
//             }

//             return v
//           }, z.date().optional().nullable().optional())
//           .optional()
//           .nullable(),
//         notes   : z.string().nullable().optional(),
//         services: z.array(
//           z.object({
//             productServiceId     : z.string(),
//             serviceShortName     : z.string(),
//             serviceQuantity      : z.number(),
//             totalSubcontractPrice: z.number().optional(),
//           }),
//         ),
//       }),
//     ),
//     projectId       : z.number(),
//     serviceExpiredAt: z.coerce.date().nullable(),
//     contractNumber  : z.number().nullable().optional(),
//     notes           : z.string().nullable(),
//     rental          : z.coerce.boolean(),
//   }),
// }) as unknown as z.Schema<ExtendedServiceContractInitializer>
