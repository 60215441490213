import { z } from 'zod'

/** Represents the enum public.order_status_type_ */
enum OrderStatusType {
  QUOTATION = 'QUOTATION',
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  LOST = 'LOST',
}

export default OrderStatusType

/** Zod schema for order_status_type_ */
export const orderStatusType = z.enum([
  'QUOTATION',
  'APPROVED',
  'ARCHIVED',
  'COMPLETED',
  'CANCELLED',
  'LOST',
])
