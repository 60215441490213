import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { serviceContractId, type ServiceContractId } from './ServiceContract.js'
import {
  servicePaymentType,
  type default as ServicePaymentType,
} from './ServicePaymentType.js'

/** Identifier type for public.service_contract_payment_ */
export type ServiceContractPaymentId = string & {
  __brand: 'ServiceContractPaymentId'
}

/** Represents the table public.service_contract_payment_ */
export default interface ServiceContractPaymentTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<
    ServiceContractPaymentId,
    ServiceContractPaymentId | undefined,
    ServiceContractPaymentId
  >

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  paymentReceivedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: public.service_payment_type_ */
  servicePaymentType: ColumnType<
    ServicePaymentType,
    ServicePaymentType,
    ServicePaymentType
  >

  /** Database type: pg_catalog.uuid */
  serviceContractId: ColumnType<
    ServiceContractId,
    ServiceContractId,
    ServiceContractId
  >

  /** Database type: pg_catalog.numeric */
  paymentAmountInCents: ColumnType<number, number, number>

  /** Database type: pg_catalog.text */
  notes: ColumnType<string | null, string | null, string | null>
}

export type ServiceContractPayment = Selectable<ServiceContractPaymentTable>

export type ServiceContractPaymentInitializer =
  Insertable<ServiceContractPaymentTable>

export type ServiceContractPaymentMutator =
  Updateable<ServiceContractPaymentTable>

export const serviceContractPaymentId =
  z.string() as unknown as z.Schema<ServiceContractPaymentId>

export const serviceContractPayment = z.object({
  id                  : serviceContractPaymentId,
  createdAt           : z.coerce.date().nullable().optional(),
  createdBy           : z.string().nullable().optional(),
  updatedAt           : z.coerce.date().nullable().optional(),
  updatedBy           : z.string().nullable().optional(),
  deletedAt           : z.coerce.date().nullable().optional(),
  deletedBy           : z.string().nullable().optional(),
  paymentReceivedAt   : z.coerce.date().nullable().optional(),
  servicePaymentType  : servicePaymentType,
  serviceContractId   : serviceContractId,
  paymentAmountInCents: z.number(),
  notes               : z.string().nullable().optional(),
}) as unknown as z.Schema<ServiceContractPayment>

export const serviceContractPaymentInitializer = z.object({
  id                  : serviceContractPaymentId.optional(),
  createdAt           : z.coerce.date().optional().nullable().optional(),
  createdBy           : z.string().optional().nullable().optional(),
  updatedAt           : z.coerce.date().optional().nullable().optional(),
  updatedBy           : z.string().optional().nullable().optional(),
  deletedAt           : z.coerce.date().optional().nullable().optional(),
  deletedBy           : z.string().optional().nullable().optional(),
  paymentReceivedAt   : z.coerce.date().optional().nullable().optional(),
  servicePaymentType  : servicePaymentType,
  serviceContractId   : serviceContractId,
  paymentAmountInCents: z.number(),
  notes               : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<ServiceContractPaymentInitializer>

export const serviceContractPaymentMutator = z.object({
  id                  : serviceContractPaymentId.optional(),
  createdAt           : z.coerce.date().optional().nullable().optional(),
  createdBy           : z.string().optional().nullable().optional(),
  updatedAt           : z.coerce.date().optional().nullable().optional(),
  updatedBy           : z.string().optional().nullable().optional(),
  deletedAt           : z.coerce.date().optional().nullable().optional(),
  deletedBy           : z.string().optional().nullable().optional(),
  paymentReceivedAt   : z.coerce.date().optional().nullable().optional(),
  servicePaymentType  : servicePaymentType.optional(),
  serviceContractId   : serviceContractId.optional(),
  paymentAmountInCents: z.number().optional(),
  notes               : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<ServiceContractPaymentMutator>
