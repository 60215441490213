import {
  Button,
  Grid,
  GridItem,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useCallback } from 'react'

import { ExtendedServiceContract, ServiceContractId } from '@beaded/models'

import { serviceManager } from 'api'

import { ModalContainer } from 'containers/ModalContainer'

import { useUserContext } from 'hooks/useUserContext'

import { queryClient } from 'lib/react-query'

const STRIPE_PROMISE = loadStripe(
  import.meta.env.VITE_STRIPE_PUBLIC_KEY as string,
)

type CreateStripePaymentProps = {
  data: ExtendedServiceContract
  iconButton?: React.ReactNode
  iconButtonProps?: any
  disabled?: boolean
  buttonText?: string
  buttonProps?: any
  tooltipText?: string
}

export const CreateStripePaymentModal = (props: CreateStripePaymentProps) => {
  return (
    <ModalContainer
      TriggerButton={({ onClick }: any) =>
        props.iconButton ? (
          <Tooltip label={props.tooltipText}>
            <IconButton
              icon={props.iconButton}
              onClick={onClick}
              disabled={props.disabled}
              {...props.iconButtonProps}
            />
          </Tooltip>
        ) : (
          <Tooltip label={props.tooltipText}>
            <Button
              onClick={onClick}
              disabled={props.disabled}
              {...props.buttonProps}
            >
              {props.buttonText}
            </Button>
          </Tooltip>
        )
      }
      modalProps={{ size: '2xl' }}
      header={`Contract #${props.data.contractNumber} - ${props.data.projectName}`}
    >
      <Grid templateColumns='repeat(4, 1fr)' gap={3}>
        <GridItem colSpan={4}>
          {props.data.totalDueInCents && props.data.totalDueInCents > 50 ? (
            <StripeCheckout
              totalPriceInCents={props.data.totalDueInCents}
              serviceContractId={props.data.id}
            />
          ) : (
            <Text>No payment required.</Text>
          )}
        </GridItem>
      </Grid>
    </ModalContainer>
  )
}

type StripeCheckoutProps = {
  totalPriceInCents: number
  serviceContractId: ServiceContractId
}

const StripeCheckout = (props: StripeCheckoutProps) => {
  const user = useUserContext()
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    // TODO: https://react-icons.github.io/react-icons/search/#q=stripe icon for the button!
    return serviceManager
      .getCheckoutSession({
        contractId       : props.serviceContractId,
        totalPriceInCents: props.totalPriceInCents,
      })
      .then((res) => {
        return res
      })
      .then((data) => data.clientSecret)
  }, [])

  const options = { fetchClientSecret }
  const eventStripeCompletedPayment = () => {
    queryClient.invalidateQueries('serviceContracts')
  }

  return (
    <>
      <EmbeddedCheckoutProvider
        stripe={STRIPE_PROMISE}
        options={{
          onComplete: eventStripeCompletedPayment,
          ...options,
        }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </>
  )
}
