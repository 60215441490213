import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { orderId, type OrderId } from './Order.js'
import { userId, type UserId } from './User.js'

/** Identifier type for public.order_line_item_ */
export type OrderLineItemId = string & { __brand: 'OrderLineItemId' }

/** Represents the table public.order_line_item_ */
export default interface OrderLineItemTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<OrderLineItemId, OrderLineItemId | undefined, OrderLineItemId>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.text */
  erpnextQuotationItemId: ColumnType<
    string | null,
    string | null,
    string | null
  >

  /** Database type: pg_catalog.jsonb */
  erpnextRawQuotationItem: ColumnType<
    unknown | null,
    unknown | null,
    unknown | null
  >

  /** Database type: pg_catalog.text */
  erpnextSalesOrderItemId: ColumnType<
    string | null,
    string | null,
    string | null
  >

  /** Database type: pg_catalog.jsonb */
  erpnextRawOrderItem: ColumnType<
    unknown | null,
    unknown | null,
    unknown | null
  >

  /** Database type: pg_catalog.int4 */
  quantity: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.text */
  lineItemName: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  lineItemDescription: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.numeric */
  lineItemRateDollars: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.numeric */
  lineItemAmountDollars: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.numeric */
  lineItemUnitWeightLbs: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.numeric */
  lineItemTotalWeightLbs: ColumnType<
    number | null,
    number | null,
    number | null
  >

  /** Database type: pg_catalog.uuid */
  orderId: ColumnType<OrderId, OrderId, OrderId>

  /** Database type: pg_catalog.text */
  erpnextBomId: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  itemCode: ColumnType<string | null, string | null, string | null>
}

export type OrderLineItem = Selectable<OrderLineItemTable>

export type OrderLineItemInitializer = Insertable<OrderLineItemTable>

export type OrderLineItemMutator = Updateable<OrderLineItemTable>

export const orderLineItemId =
  z.string() as unknown as z.Schema<OrderLineItemId>

export const orderLineItem = z.object({
  id                     : orderLineItemId,
  createdAt              : z.coerce.date().nullable().optional(),
  createdBy              : userId.nullable().optional(),
  updatedAt              : z.coerce.date().nullable().optional(),
  updatedBy              : userId.nullable().optional(),
  deletedAt              : z.coerce.date().nullable().optional(),
  deletedBy              : userId.nullable().optional(),
  erpnextQuotationItemId : z.string().nullable().optional(),
  erpnextRawQuotationItem: z.unknown().nullable().optional(),
  erpnextSalesOrderItemId: z.string().nullable().optional(),
  erpnextRawOrderItem    : z.unknown().nullable().optional(),
  quantity               : z.number().nullable().optional(),
  lineItemName           : z.string().nullable().optional(),
  lineItemDescription    : z.string().nullable().optional(),
  lineItemRateDollars    : z.number().nullable().optional(),
  lineItemAmountDollars  : z.number().nullable().optional(),
  lineItemUnitWeightLbs  : z.number().nullable().optional(),
  lineItemTotalWeightLbs : z.number().nullable().optional(),
  orderId                : orderId,
  erpnextBomId           : z.string().nullable().optional(),
  itemCode               : z.string().nullable().optional(),
}) as unknown as z.Schema<OrderLineItem>

export const orderLineItemInitializer = z.object({
  id                     : orderLineItemId.optional(),
  createdAt              : z.coerce.date().optional().nullable().optional(),
  createdBy              : userId.optional().nullable().optional(),
  updatedAt              : z.coerce.date().optional().nullable().optional(),
  updatedBy              : userId.optional().nullable().optional(),
  deletedAt              : z.coerce.date().optional().nullable().optional(),
  deletedBy              : userId.optional().nullable().optional(),
  erpnextQuotationItemId : z.string().optional().nullable().optional(),
  erpnextRawQuotationItem: z.unknown().optional().nullable().optional(),
  erpnextSalesOrderItemId: z.string().optional().nullable().optional(),
  erpnextRawOrderItem    : z.unknown().optional().nullable().optional(),
  quantity               : z.number().optional().nullable().optional(),
  lineItemName           : z.string().optional().nullable().optional(),
  lineItemDescription    : z.string().optional().nullable().optional(),
  lineItemRateDollars    : z.number().optional().nullable().optional(),
  lineItemAmountDollars  : z.number().optional().nullable().optional(),
  lineItemUnitWeightLbs  : z.number().optional().nullable().optional(),
  lineItemTotalWeightLbs : z.number().optional().nullable().optional(),
  orderId                : orderId,
  erpnextBomId           : z.string().optional().nullable().optional(),
  itemCode               : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<OrderLineItemInitializer>

export const orderLineItemMutator = z.object({
  id                     : orderLineItemId.optional(),
  createdAt              : z.coerce.date().optional().nullable().optional(),
  createdBy              : userId.optional().nullable().optional(),
  updatedAt              : z.coerce.date().optional().nullable().optional(),
  updatedBy              : userId.optional().nullable().optional(),
  deletedAt              : z.coerce.date().optional().nullable().optional(),
  deletedBy              : userId.optional().nullable().optional(),
  erpnextQuotationItemId : z.string().optional().nullable().optional(),
  erpnextRawQuotationItem: z.unknown().optional().nullable().optional(),
  erpnextSalesOrderItemId: z.string().optional().nullable().optional(),
  erpnextRawOrderItem    : z.unknown().optional().nullable().optional(),
  quantity               : z.number().optional().nullable().optional(),
  lineItemName           : z.string().optional().nullable().optional(),
  lineItemDescription    : z.string().optional().nullable().optional(),
  lineItemRateDollars    : z.number().optional().nullable().optional(),
  lineItemAmountDollars  : z.number().optional().nullable().optional(),
  lineItemUnitWeightLbs  : z.number().optional().nullable().optional(),
  lineItemTotalWeightLbs : z.number().optional().nullable().optional(),
  orderId                : orderId.optional(),
  erpnextBomId           : z.string().optional().nullable().optional(),
  itemCode               : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<OrderLineItemMutator>
