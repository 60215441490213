import memoize from 'memoizee'

import { LengthUnitType } from '@beaded/models'

/**
 * @param {number} length
 * @param {('ft' | 'm' | 'cm' | 'in')} from
 * @param {('ft' | 'm' | 'cm' | 'in')} to
 * @returns {number}
 */
export const convertLength = (
  length: number,
  from: LengthUnitType,
  to: LengthUnitType,
) => {
  if (!from || !to) {
    console.warn('undefined from or to', { length, from, to })
  }
  // if either unit is lowercase, console.warn
  // if (from !== from.toUpperCase() || to !== to.toUpperCase()) {
  //   // console.warn('convertLength: lowercase unit', { length, from, to })
  // }

  // uppercase them just to be sure we're on the same page
  from = from.toUpperCase() as LengthUnitType
  to = to.toUpperCase() as LengthUnitType

  const conversionKey = `${from}->${to}` as keyof typeof conversions
  if (Object.keys(conversions).includes(conversionKey)) {
    return parseFloat(
      conversions[conversionKey](length).toFixed(getPrecision(to)),
    )
  }

  if (typeof length !== 'number') length = parseFloat(length)
  return parseFloat(length.toFixed(getPrecision(to))) // toFixed returns a string
}

const conversions = {
  'FT->MM': (d: number) => d * 304.8,
  'FT->CM': (d: number) => d * 30.48,
  'FT->M' : (d: number) => d * 0.3048,
  'FT->IN': (d: number) => d * 12,

  'MM->CM': (d: number) => d * 0.1,
  'MM->M' : (d: number) => d * 0.001,
  'MM->IN': (d: number) => d * 0.0393701,
  'MM->FT': (d: number) => d * 0.00328084,

  'CM->MM': (d: number) => d * 10,
  'CM->M' : (d: number) => d * 0.01,
  'CM->IN': (d: number) => d * 0.393701,
  'CM->FT': (d: number) => d * 0.0328084,

  'M->MM': (d: number) => d * 1000,
  'M->CM': (d: number) => d * 100,
  'M->IN': (d: number) => d * 39.3701,
  'M->FT': (d: number) => d * 3.28084,

  'IN->MM': (d: number) => d * 25.4,
  'IN->CM': (d: number) => d * 2.54,
  'IN->M' : (d: number) => d * 0.0254,
  'IN->FT': (d: number) => d * (1 / 12),
} as const

// import memoize from 'memoizee'

export const getPrecision = memoize((units: LengthUnitType) => {
  switch (units) {
    case 'FT':
      return 2
    case 'IN':
      return 1
    case 'CM':
      return 1
    case 'M':
      return 3
    case 'MM':
      return 0
    default:
      return 0
  }
})
