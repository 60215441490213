import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  cableConnectorType,
  type default as CableConnectorType,
} from './CableConnectorType.js'
import {
  cableHeatshrinkType,
  type default as CableHeatshrinkType,
} from './CableHeatshrinkType.js'
import { cablePriceId, type CablePriceId } from './CablePrice.js'
import {
  cableStatusType,
  type default as CableStatusType,
} from './CableStatusType.js'
import { cableType, type default as CableType } from './CableType.js'
import {
  lengthUnitType,
  type default as LengthUnitType,
} from './LengthUnitType.js'

/** Identifier type for public.cable_ */
export type CableId = number & { __brand: 'CableId' }

/** Represents the table public.cable_ */
export default interface CableTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<CableId, CableId | undefined, CableId>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  name: ColumnType<string, string, string>

  /** Database type: pg_catalog.text */
  serialNumber: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  locked: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.text */
  internalNotes: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  salesNotes: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  productionNotes: ColumnType<string | null, string | null, string | null>

  /** Database type: public.cable_status_type_ */
  status: ColumnType<
    CableStatusType | null,
    CableStatusType | null,
    CableStatusType | null
  >

  /** Database type: pg_catalog.text */
  packaging: ColumnType<string | null, string | null, string | null>

  /** Database type: public.cable_heatshrink_type_ */
  heatshrink: ColumnType<
    CableHeatshrinkType | null,
    CableHeatshrinkType | null,
    CableHeatshrinkType | null
  >

  /** Database type: public.cable_connector_type_ */
  connector: ColumnType<
    CableConnectorType | null,
    CableConnectorType | null,
    CableConnectorType | null
  >

  /** Database type: pg_catalog.int4 */
  quantity: ColumnType<number | null, number | null, number | null>

  /** Database type: public.cable_type_ */
  cableType: ColumnType<CableType | null, CableType | null, CableType | null>

  /** Database type: pg_catalog.numeric */
  priceDiscountAmount: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.text */
  priceDiscountType: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  priceDiscountRaw: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  priceDiscountReason: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.numeric */
  priceAdditionalChargesAmount: ColumnType<
    number | null,
    number | null,
    number | null
  >

  /** Database type: pg_catalog.text */
  priceAdditionalChargesNotes: ColumnType<
    string | null,
    string | null,
    string | null
  >

  /** Database type: pg_catalog.uuid */
  priceModelId: ColumnType<
    CablePriceId | null,
    CablePriceId | null,
    CablePriceId | null
  >

  /** Database type: pg_catalog.numeric */
  priceUnit: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.numeric */
  priceTotal: ColumnType<number | null, number | null, number | null>

  /** Database type: public.length_unit_type_ */
  lengthUnits: ColumnType<LengthUnitType, LengthUnitType, LengthUnitType>
}

export type Cable = Selectable<CableTable>

export type CableInitializer = Insertable<CableTable>

export type CableMutator = Updateable<CableTable>

export const cableId = z.number() as unknown as z.Schema<CableId>

export const cable = z.object({
  id                          : cableId,
  createdAt                   : z.coerce.date().nullable().optional(),
  createdBy                   : z.string().nullable().optional(),
  updatedAt                   : z.coerce.date().nullable().optional(),
  updatedBy                   : z.string().nullable().optional(),
  deletedAt                   : z.coerce.date().nullable().optional(),
  deletedBy                   : z.string().nullable().optional(),
  name                        : z.string(),
  serialNumber                : z.string().nullable().optional(),
  locked                      : z.boolean().nullable().optional(),
  internalNotes               : z.string().nullable().optional(),
  salesNotes                  : z.string().nullable().optional(),
  productionNotes             : z.string().nullable().optional(),
  status                      : cableStatusType.nullable().optional(),
  packaging                   : z.string().nullable().optional(),
  heatshrink                  : cableHeatshrinkType.nullable().optional(),
  connector                   : cableConnectorType.nullable().optional(),
  quantity                    : z.number().nullable().optional(),
  cableType                   : cableType.nullable().optional(),
  priceDiscountAmount         : z.number().nullable().optional(),
  priceDiscountType           : z.string().nullable().optional(),
  priceDiscountRaw            : z.string().nullable().optional(),
  priceDiscountReason         : z.string().nullable().optional(),
  priceAdditionalChargesAmount: z.number().nullable().optional(),
  priceAdditionalChargesNotes : z.string().nullable().optional(),
  priceModelId                : cablePriceId.nullable().optional(),
  priceUnit                   : z.number().nullable().optional(),
  priceTotal                  : z.number().nullable().optional(),
  lengthUnits                 : lengthUnitType,
}) as unknown as z.Schema<Cable>

export const cableInitializer = z.object({
  id                          : cableId.optional(),
  createdAt                   : z.coerce.date().optional().nullable().optional(),
  createdBy                   : z.string().optional().nullable().optional(),
  updatedAt                   : z.coerce.date().optional().nullable().optional(),
  updatedBy                   : z.string().optional().nullable().optional(),
  deletedAt                   : z.coerce.date().optional().nullable().optional(),
  deletedBy                   : z.string().optional().nullable().optional(),
  name                        : z.string(),
  serialNumber                : z.string().optional().nullable().optional(),
  locked                      : z.boolean().optional().nullable().optional(),
  internalNotes               : z.string().optional().nullable().optional(),
  salesNotes                  : z.string().optional().nullable().optional(),
  productionNotes             : z.string().optional().nullable().optional(),
  status                      : cableStatusType.optional().nullable().optional(),
  packaging                   : z.string().optional().nullable().optional(),
  heatshrink                  : cableHeatshrinkType.optional().nullable().optional(),
  connector                   : cableConnectorType.optional().nullable().optional(),
  quantity                    : z.number().optional().nullable().optional(),
  cableType                   : cableType.optional().nullable().optional(),
  priceDiscountAmount         : z.number().optional().nullable().optional(),
  priceDiscountType           : z.string().optional().nullable().optional(),
  priceDiscountRaw            : z.string().optional().nullable().optional(),
  priceDiscountReason         : z.string().optional().nullable().optional(),
  priceAdditionalChargesAmount: z.number().optional().nullable().optional(),
  priceAdditionalChargesNotes : z.string().optional().nullable().optional(),
  priceModelId                : cablePriceId.optional().nullable().optional(),
  priceUnit                   : z.number().optional().nullable().optional(),
  priceTotal                  : z.number().optional().nullable().optional(),
  lengthUnits                 : lengthUnitType,
}) as unknown as z.Schema<CableInitializer>

export const cableMutator = z.object({
  id                          : cableId.optional(),
  createdAt                   : z.coerce.date().optional().nullable().optional(),
  createdBy                   : z.string().optional().nullable().optional(),
  updatedAt                   : z.coerce.date().optional().nullable().optional(),
  updatedBy                   : z.string().optional().nullable().optional(),
  deletedAt                   : z.coerce.date().optional().nullable().optional(),
  deletedBy                   : z.string().optional().nullable().optional(),
  name                        : z.string().optional(),
  serialNumber                : z.string().optional().nullable().optional(),
  locked                      : z.boolean().optional().nullable().optional(),
  internalNotes               : z.string().optional().nullable().optional(),
  salesNotes                  : z.string().optional().nullable().optional(),
  productionNotes             : z.string().optional().nullable().optional(),
  status                      : cableStatusType.optional().nullable().optional(),
  packaging                   : z.string().optional().nullable().optional(),
  heatshrink                  : cableHeatshrinkType.optional().nullable().optional(),
  connector                   : cableConnectorType.optional().nullable().optional(),
  quantity                    : z.number().optional().nullable().optional(),
  cableType                   : cableType.optional().nullable().optional(),
  priceDiscountAmount         : z.number().optional().nullable().optional(),
  priceDiscountType           : z.string().optional().nullable().optional(),
  priceDiscountRaw            : z.string().optional().nullable().optional(),
  priceDiscountReason         : z.string().optional().nullable().optional(),
  priceAdditionalChargesAmount: z.number().optional().nullable().optional(),
  priceAdditionalChargesNotes : z.string().optional().nullable().optional(),
  priceModelId                : cablePriceId.optional().nullable().optional(),
  priceUnit                   : z.number().optional().nullable().optional(),
  priceTotal                  : z.number().optional().nullable().optional(),
  lengthUnits                 : lengthUnitType.optional(),
}) as unknown as z.Schema<CableMutator>
