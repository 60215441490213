import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { MdArrowDropDown } from 'react-icons/md'

import { BaseInputProps } from 'forms/helpers/types'

export type SelectInputProps = BaseInputProps & {
  options: string[] | Array<{ name: string; value: string | number }>
}

export const SelectInput = (props: SelectInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <FormControl
      isRequired={Boolean(props?.hookformProps?.required)}
      hidden={props.hidden ?? false}
    >
      <FormLabel htmlFor={props.name}>{props.label ?? props.name}</FormLabel>
      {props.description && <Text>{props.description}</Text>}
      <Select
        {...register(props.name, props.hookformProps)}
        placeholder={props.placeholder ?? 'Select an option'}
        icon={props.isLoading ? <Spinner /> : <MdArrowDropDown />}
      >
        {props.options.map((option) => {
          if (typeof option === 'string') {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            )
          }
          return (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          )
        })}
      </Select>
      <FormErrorMessage>{errors[props.name]?.message}</FormErrorMessage>
    </FormControl>
  )
}
