import {
  HStack,
  Spacer,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Select,
  Button,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import memoize from 'memoizee'
import { useState } from 'react'

import { UserId } from '@beaded/models'

import { perm } from 'api'

import { ErrorCard } from 'components/ErrorCard'
import { ErrorWrapper } from 'components/ErrorWrapper'
import { Loading } from 'components/Loading'

import { Card } from 'containers/Card'

import { useDocumentTitle } from 'hooks/useDocumentTitle'
import { useRouter } from 'hooks/useRouter'
import { useOrgSelectOptions } from 'hooks/useSelectOptions'

import { queryClient } from 'lib/react-query'

import type { Option } from 'types/FormInfo'

import { OrgBlock } from './OrgBlock.component'
import { useUserData } from './useUserData'

export default function UserWrapper() {
  const {
    match: {
      params: { id: userId },
    },
  } = useRouter()
  const { isLoading, errors, user } = useUserData(userId as UserId)
  useDocumentTitle(`${user?.name?.first} ${user?.name?.last}`)

  if (isLoading) return <Loading />

  if (errors) return <ErrorCard errors={errors} />

  return (
    <ErrorWrapper>
      <Card>
        <HStack pb='1rem'>
          <Text layerStyle='heading'>User</Text>
          <Spacer />
        </HStack>

        <Table>
          <Tbody>
            <Tr>
              <Th>
                {user.nameFirst} {user.nameLast}
              </Th>
            </Tr>
          </Tbody>
        </Table>
      </Card>
      <Card>
        <PermsBlock userId={userId!} />
      </Card>
    </ErrorWrapper>
  )
}

interface PermsBlockProps {
  userId: number | string
  user?: any
}

export const PermsBlock = ({ userId, user }: PermsBlockProps) => {
  const { isLoading, errors, orgNames, perms } = useUserData(userId as UserId)

  const asyncOpts = useOrgSelectOptions()
  const [addOrg, setAddOrg] = useState<number>(0)

  const reduceOptions = memoize(
    (): Array<{
      name: string
      value: any
    }> | null =>
      Array.isArray(asyncOpts?.options)
        ? asyncOpts?.options?.map(({ value, name }: Option) => ({
          name,
          value,
        }))
        : null,
  )

  const addOrgToUser = async () => {
    if (addOrg) {
      await perm.create(userId, { org: { id: addOrg } })
      queryClient.invalidateQueries(['perms', { 'user.id': userId }])
      setAddOrg(0)
    }
  }

  if (isLoading) return <Loading />

  if (errors) return <ErrorCard errors={errors} />

  return (
    <ErrorWrapper>
      <HStack pb='1rem'>
        <Text layerStyle='heading'>
          {user?.name?.first} {user?.name?.last}
        </Text>
        <Spacer />
      </HStack>

      <Grid templateColumns='1fr 1fr' pb='2rem'>
        <GridItem>
          <Text>Add an organization for user</Text>
        </GridItem>

        <GridItem />

        <GridItem>
          <Select
            size='sm'
            value={addOrg}
            onChange={(e) => setAddOrg(Number(e.target.value))}
            maxW='40rem'
          >
            <option value={0}>Select an organization</option>
            {reduceOptions()?.map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </Select>
        </GridItem>

        <GridItem>
          <Button size='sm' onClick={addOrgToUser}>
            Add Org
          </Button>
        </GridItem>
      </Grid>

      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Org</Th>
            <Th>Project</Th>
            <Th>Admin</Th>
            <Th>Edit</Th>
            <Th>View</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(orgNames).map(([id, org], index, array) => (
            <OrgBlock
              key={`orgId:${id}`}
              org={{
                org,
                perm: perms?.org?.find((o: any) => o?.id === parseInt(id)),
              }}
              perms={perms?.project?.filter(
                (p: any) => p?.extras?.org?.id === parseInt(id),
              )}
              userId={userId!}
              lastSection={index === array.length - 1}
            />
          ))}
        </Tbody>
      </Table>
    </ErrorWrapper>
  )
}
