import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  orderStatusType,
  type default as OrderStatusType,
} from './OrderStatusType.js'
import { organizationId, type OrganizationId } from './Organization.js'
import { projectId, type ProjectId } from './Project.js'
import { userId, type UserId } from './User.js'

/** Identifier type for public.order_ */
export type OrderId = string & { __brand: 'OrderId' }

/** Represents the table public.order_ */
export default interface OrderTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<OrderId, OrderId | undefined, OrderId>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.text */
  erpnextQuotationId: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.jsonb */
  erpnextRawQuotation: ColumnType<
    unknown | null,
    unknown | null,
    unknown | null
  >

  /** Database type: pg_catalog.text */
  erpnextSalesOrderId: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.jsonb */
  erpnextRawOrder: ColumnType<unknown | null, unknown | null, unknown | null>

  /** Database type: pg_catalog.text */
  opportunityName: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.int4 */
  organizationId: ColumnType<OrganizationId, OrganizationId, OrganizationId>

  /** Database type: pg_catalog.int4 */
  projectId: ColumnType<ProjectId | null, ProjectId | null, ProjectId | null>

  /** Database type: pg_catalog.text */
  notes: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  currency: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.numeric */
  totalPriceDollars: ColumnType<number | null, number | null, number | null>

  /** Database type: public.order_status_type_ */
  status: ColumnType<
    OrderStatusType | null,
    OrderStatusType | null,
    OrderStatusType | null
  >
}

export type Order = Selectable<OrderTable>

export type OrderInitializer = Insertable<OrderTable>

export type OrderMutator = Updateable<OrderTable>

export const orderId = z.string() as unknown as z.Schema<OrderId>

export const order = z.object({
  id                 : orderId,
  createdAt          : z.coerce.date().nullable().optional(),
  createdBy          : userId.nullable().optional(),
  updatedAt          : z.coerce.date().nullable().optional(),
  updatedBy          : userId.nullable().optional(),
  deletedAt          : z.coerce.date().nullable().optional(),
  deletedBy          : userId.nullable().optional(),
  erpnextQuotationId : z.string().nullable().optional(),
  erpnextRawQuotation: z.unknown().nullable().optional(),
  erpnextSalesOrderId: z.string().nullable().optional(),
  erpnextRawOrder    : z.unknown().nullable().optional(),
  opportunityName    : z.string().nullable().optional(),
  organizationId     : organizationId,
  projectId          : projectId.nullable().optional(),
  notes              : z.string().nullable().optional(),
  currency           : z.string().nullable().optional(),
  totalPriceDollars  : z.number().nullable().optional(),
  status             : orderStatusType.nullable().optional(),
}) as unknown as z.Schema<Order>

export const orderInitializer = z.object({
  id                 : orderId.optional(),
  createdAt          : z.coerce.date().optional().nullable().optional(),
  createdBy          : userId.optional().nullable().optional(),
  updatedAt          : z.coerce.date().optional().nullable().optional(),
  updatedBy          : userId.optional().nullable().optional(),
  deletedAt          : z.coerce.date().optional().nullable().optional(),
  deletedBy          : userId.optional().nullable().optional(),
  erpnextQuotationId : z.string().optional().nullable().optional(),
  erpnextRawQuotation: z.unknown().optional().nullable().optional(),
  erpnextSalesOrderId: z.string().optional().nullable().optional(),
  erpnextRawOrder    : z.unknown().optional().nullable().optional(),
  opportunityName    : z.string().optional().nullable().optional(),
  organizationId     : organizationId,
  projectId          : projectId.optional().nullable().optional(),
  notes              : z.string().optional().nullable().optional(),
  currency           : z.string().optional().nullable().optional(),
  totalPriceDollars  : z.number().optional().nullable().optional(),
  status             : orderStatusType.optional().nullable().optional(),
}) as unknown as z.Schema<OrderInitializer>

export const orderMutator = z.object({
  id                 : orderId.optional(),
  createdAt          : z.coerce.date().optional().nullable().optional(),
  createdBy          : userId.optional().nullable().optional(),
  updatedAt          : z.coerce.date().optional().nullable().optional(),
  updatedBy          : userId.optional().nullable().optional(),
  deletedAt          : z.coerce.date().optional().nullable().optional(),
  deletedBy          : userId.optional().nullable().optional(),
  erpnextQuotationId : z.string().optional().nullable().optional(),
  erpnextRawQuotation: z.unknown().optional().nullable().optional(),
  erpnextSalesOrderId: z.string().optional().nullable().optional(),
  erpnextRawOrder    : z.unknown().optional().nullable().optional(),
  opportunityName    : z.string().optional().nullable().optional(),
  organizationId     : organizationId.optional(),
  projectId          : projectId.optional().nullable().optional(),
  notes              : z.string().optional().nullable().optional(),
  currency           : z.string().optional().nullable().optional(),
  totalPriceDollars  : z.number().optional().nullable().optional(),
  status             : orderStatusType.optional().nullable().optional(),
}) as unknown as z.Schema<OrderMutator>
