import { type ColumnType, type Selectable } from 'kysely'
import { z } from 'zod'

import {
  productDurationType,
  type default as ProductDurationType,
} from './ProductDurationType.js'
import {
  productServiceHistoryId,
  type ProductServiceHistoryId,
} from './ProductServiceHistory.js'
import {
  productUnitType,
  type default as ProductUnitType,
} from './ProductUnitType.js'

/** Represents the view public.current_service_product_price_view_ */
export default interface CurrentServiceProductPriceViewTable {
  /** Database type: pg_catalog.uuid */
  priceHistoryId: ColumnType<ProductServiceHistoryId, never, never>

  /** Database type: pg_catalog.timestamptz */
  productHistory: ColumnType<Date | null, never, never>

  /** Database type: pg_catalog.timestamptz */
  productService: ColumnType<Date | null, never, never>

  /** Database type: pg_catalog.text */
  serviceFullName: ColumnType<string, never, never>

  /** Database type: pg_catalog.text */
  serviceShortName: ColumnType<string, never, never>

  /** Database type: pg_catalog.int4 */
  currentPrice: ColumnType<number, never, never>

  /** Database type: public.product_duration_type_ */
  productDurationType: ColumnType<ProductDurationType | null, never, never>

  /** Database type: public.product_unit_type_ */
  productUnitType: ColumnType<ProductUnitType | null, never, never>
}

export type CurrentServiceProductPriceView =
  Selectable<CurrentServiceProductPriceViewTable>

export const currentServiceProductPriceView = z.object({
  priceHistoryId     : productServiceHistoryId,
  productHistory     : z.coerce.date().nullable().optional(),
  productService     : z.coerce.date().nullable().optional(),
  serviceFullName    : z.string(),
  serviceShortName   : z.string(),
  currentPrice       : z.number(),
  productDurationType: productDurationType.nullable().optional(),
  productUnitType    : productUnitType.nullable().optional(),
}) as unknown as z.Schema<CurrentServiceProductPriceView>
