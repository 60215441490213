import { z } from 'zod'

/** Represents the enum public.product_unit_type_ */
enum ProductUnitType {
  EACH = 'EACH',
  LOGGER = 'LOGGER',
  SDM = 'SDM',
  HOURS = 'HOURS',
}

export default ProductUnitType

/** Zod schema for product_unit_type_ */
export const productUnitType = z.enum(['EACH', 'LOGGER', 'SDM', 'HOURS'])
