import { z } from 'zod'

import { Cable, CableConnectorType } from '../../index.js'

enum CableConduitType {
  NONE = 'NONE',
  FLEX = 'FLEX',
  PEX = 'PEX',
  PEXSAND = 'PEXSAND',
  HDPE = 'HDPE',
  STAINLESS = 'STAINLESS',
}

enum CableLeadComponentType {
  NO_MLINK = 'NO_MLINK',
  MLINK = 'MLINK',
  BARE = 'BARE',
  XLR = 'XLR',
  RAYMO = 'RAYMO',
  NONE = 'NONE',
}

const cableLeadComponentType = z.enum([
  'NO_MLINK',
  'MLINK',
  'BARE',
  'XLR',
  'RAYMO',
  'NONE',
])

const cableConduitType = z.enum([
  'NONE',
  'FLEX',
  'PEX',
  'PEXSAND',
  'HDPE',
  'STAINLESS',
])

type ExtendedCable = Cable & {
  lead: {
    component: CableConnectorType
  }
}

interface MaybeCable {
  name: string
  serial: number
  notes: null
  productionComment: string
  status: null
  connector: string
  packaging: string
  heatshrink: string
  mlink: boolean
  quantity: number
  lead: Body[]
  body: Body[]
  locked: boolean
  // priceChart: PriceChart
  events: Event[]
  // use: Use[]
  // display: Display
  id: number
  // by_user: ByUser
  v: number
}

interface Body {
  type: string
  component: `Protection / Sensor ${number}` | `Sensor ${number}`
  mold?: Mold
  position: number
  length: number
  order?: number
  cableColor: string
  conduit: string
}

interface Mold {
  type: string
  color: string
}

export type LeadSection = {
  type: 'lead'
  length: number
  position: number
  cableColor: string
  conduit?: CableConduitType
  component: CableLeadComponentType
}

export type BodySection = {
  type: 'body'
  length: number
  position: number
  cableColor: string
  conduit?: string
  component: `Protection / Sensor ${number}` | `Sensor ${number}`
  mold: Mold
  order?: number
}
