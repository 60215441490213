import React from 'react'
import type { LazyExoticComponent } from 'react'
import { Route } from 'react-router-dom'

import { GlobalRoleType, UserWithPermissions } from '@beaded/models'

import { PrivateRoute } from './PrivateRoute'

export interface IRoute {
  path: string
  name: string
  Component: LazyExoticComponent<() => JSX.Element>
  sidebar?: boolean | Function
  divider?: boolean
  external?: boolean
  requiredPermissions?: (
    permissions: UserWithPermissions['permissions'] | null,
  ) => boolean
  requiresGlobalUser?: boolean
  limitUserVisibility?: boolean
}

export interface IDivider {
  divider: true
  sidebar: true
  path?: undefined
  name?: undefined
  Component?: undefined
  requiredPermissions?: (
    permissions: UserWithPermissions['permissions'] | null,
  ) => boolean
  requiresGlobalUser?: boolean
}

export const unAuth: Array<IRoute | IDivider> = [
  {
    path     : '/mapping',
    name     : '',
    Component: React.lazy(() => import('pages/Mapping')),
  },

  {
    path     : '/styles',
    name     : '',
    Component: React.lazy(() => import('pages/Styles')),
  },

  {
    path     : '/public/projects',
    name     : '',
    Component: React.lazy(() => import('pages/PublicProjects')),
  },

  {
    path     : '/public/projects/:id',
    name     : '',
    Component: React.lazy(() => import('pages/PublicProject')),
  },

  {
    path     : '/public/orgs/',
    name     : '',
    Component: React.lazy(() => import('pages/PublicOrgs')),
  },

  {
    path     : '/public/orgs/:id',
    name     : '',
    Component: React.lazy(() => import('pages/PublicOrg')),
  },

  {
    path     : '/public/sites',
    name     : '',
    Component: React.lazy(() => import('pages/PublicSites')),
  },

  {
    path     : '/public/sites/:id',
    name     : '',
    Component: React.lazy(() => import('pages/PublicSite')),
  },

  {
    path     : '/forgot-password',
    name     : 'Forgot Password',
    Component: React.lazy(() => import('pages/ForgotPassword')),
  },

  {
    path     : '/reset/:id',
    name     : 'Reset Password',
    Component: React.lazy(() => import('pages/ResetPassword')),
  },

  {
    path     : '/login',
    name     : 'login',
    Component: React.lazy(() => import('pages/Login')),
  },
]

export const auth: Array<IRoute | IDivider> = [
  {
    path     : '/',
    name     : 'Home',
    Component: React.lazy(() => import('pages/Home')),
    sidebar  : true,
  },

  {
    path     : '/projects',
    name     : 'Projects',
    Component: React.lazy(() => import('pages/Projects')),
    sidebar  : true,
  },

  {
    path     : '/sites',
    name     : 'Sites',
    Component: React.lazy(() => import('pages/Sites')),
    sidebar  : true,
  },

  {
    path               : '/cables',
    name               : 'Cables',
    Component          : React.lazy(() => import('pages/Cables')),
    sidebar            : true,
    limitUserVisibility: true,
  },

  {
    path               : '/drafts',
    name               : 'Cable Drafts',
    Component          : React.lazy(() => import('pages/DraftCables')),
    sidebar            : true,
    limitUserVisibility: true,
  },

  {
    path     : '/orgs/:id',
    name     : 'Org',
    Component: React.lazy(() => import('pages/Org')),
  },

  {
    path     : '/projects/:id',
    name     : '',
    Component: React.lazy(() => import('pages/Project')),
  },

  {
    path     : '/projects/:id/report',
    name     : '',
    Component: React.lazy(() => import('pages/ProjectReport')),
  },

  {
    path     : '/sites/:id',
    name     : '',
    Component: React.lazy(() => import('pages/Site')),
  },

  { sidebar: true, divider: true, requiresGlobalUser: true },

  {
    path              : '/orgs',
    name              : 'Organizations',
    Component         : React.lazy(() => import('pages/Orgs')),
    sidebar           : true,
    requiresGlobalUser: true,
  },

  {
    path              : '/orders',
    name              : 'Orders',
    Component         : React.lazy(() => import('pages/Orders')),
    sidebar           : true,
    requiresGlobalUser: true,
  },

  {
    path              : '/orders/:id',
    name              : 'Orders',
    Component         : React.lazy(() => import('pages/Order')),
    sidebar           : false,
    requiresGlobalUser: true,
  },

  {
    path     : '/loggers',
    name     : 'Loggers',
    Component: React.lazy(() => import('pages/Loggers')),
    sidebar  : true,
  },

  {
    path              : '/sensors',
    name              : 'Sensors',
    Component         : React.lazy(() => import('pages/Sensors')),
    sidebar           : true,
    requiresGlobalUser: true,
  },

  {
    path              : '/users',
    name              : 'Users',
    Component         : React.lazy(() => import('pages/Users')),
    sidebar           : true,
    requiresGlobalUser: true,
  },

  {
    path     : '/users/:id',
    name     : 'Users',
    Component: React.lazy(() => import('pages/User')),
  },

  { sidebar: true, divider: true, requiresGlobalUser: true },

  {
    path              : '/cables/:id',
    name              : 'Cable Builder',
    Component         : React.lazy(() => import('pages/Cable')),
    sidebar           : (path: string) => path.replace(':id', 'new'),
    requiresGlobalUser: true,
  },

  {
    path              : '/loggers/status',
    name              : 'Logger Status',
    Component         : React.lazy(() => import('pages/LoggerStatus')),
    sidebar           : true,
    requiresGlobalUser: true,
  },

  {
    path              : '/logger/longevity',
    name              : 'Logger Longevity',
    Component         : React.lazy(() => import('pages/LoggerLongevity')),
    sidebar           : false,
    requiresGlobalUser: true,
  },

  {
    path     : '/loggers/:id',
    name     : '',
    Component: React.lazy(() => import('pages/Logger')),
  },

  {
    path              : '/modems',
    name              : 'Modems',
    Component         : React.lazy(() => import('pages/Modems')),
    sidebar           : true,
    requiresGlobalUser: true,
  },

  {
    path              : '/modems/:imei',
    name              : 'Modem',
    Component         : React.lazy(() => import('pages/Modem')),
    requiresGlobalUser: true,
  },

  {
    path              : '/reading-errors',
    name              : 'Reading Errors',
    Component         : React.lazy(() => import('pages/ReadingErrors')),
    sidebar           : true,
    requiresGlobalUser: true,
  },

  {
    path              : '/recent-users',
    name              : 'Recent Users',
    Component         : React.lazy(() => import('pages/RecentUsers')),
    sidebar           : true,
    requiresGlobalUser: true,
  },

  {
    path              : '/search',
    name              : 'Search',
    Component         : React.lazy(() => import('pages/Search')),
    requiresGlobalUser: true,
  },

  {
    path              : '/commands',
    name              : 'Logger Commands',
    Component         : React.lazy(() => import('pages/LoggerCommands')),
    requiresGlobalUser: true,
    sidebar           : true,
  },

  {
    path              : '/data-usage-calculator',
    name              : 'Data Usage Calculator',
    Component         : React.lazy(() => import('pages/DataUsageCalculator')),
    requiresGlobalUser: true,
    sidebar           : true,
  },

  {
    path               : '/service-manager',
    name               : 'Service Manager',
    Component          : React.lazy(() => import('pages/ServiceManager')),
    requiredPermissions: (permissions) =>
      permissions?.global?.some(
        (p) =>
          p.role === GlobalRoleType.GLOBAL_EDIT ||
          p.role === GlobalRoleType.GLOBAL_ADMIN,
      ) || false,
    sidebar            : true,
    limitUserVisibility: true,
  },

  {
    path               : '/logger-firmware-management',
    name               : 'Logger Firmware Management',
    Component          : React.lazy(() => import('pages/LoggerFirmwareManagement')),
    requiredPermissions: (permissions) =>
      permissions?.global?.some(
        (p) => p.role === GlobalRoleType.LOGGER_ADMIN,
      ) || false,
    sidebar: true,
  },
  {
    path               : '/cable-pricing',
    name               : 'Cable Pricing',
    Component          : React.lazy(() => import('pages/CablePricing')),
    requiredPermissions: (permissions) =>
      permissions?.global?.some((p) => p.role === GlobalRoleType.SALES_ADMIN) ||
      false,
    sidebar: true,
  },
  {
    path     : '/vcard',
    name     : 'VCard',
    Component: React.lazy(() => import('pages/VCard')),
  },
]

export const drawerContents = auth
  .filter(({ sidebar }) => Boolean(sidebar))
  .map(({ path, sidebar, ...rest }) => ({
    href: typeof sidebar === 'function' ? sidebar(path) : path,
    ...rest,
  }))

export const unAuthRoutes = unAuth
  .filter(({ divider }) => divider === undefined)
  .map(({ path, Component }) => (
    // @ts-ignore: TS2322
    <Route exact path={path as string} component={Component} key={path} />
  ))

export const authRoutes = auth
  .filter(({ divider }) => !divider)
  .map(({ path, Component }) => (
    <PrivateRoute exact path={path} component={Component} key={path} />
  ))

// eslint-disable-next-line import/no-anonymous-default-export
export default [...unAuth, ...auth]
