import { z } from 'zod'

/** Represents the enum public.api_key_type_ */
enum ApiKeyType {
  READONLY = 'READONLY',
  CAPTUREAPP = 'CAPTUREAPP',
  SDM = 'SDM',
  ETHERNET_ACTIVATOR = 'ETHERNET_ACTIVATOR',
  ERPNEXT = 'ERPNEXT',
}

export default ApiKeyType

/** Zod schema for api_key_type_ */
export const apiKeyType = z.enum([
  'READONLY',
  'CAPTUREAPP',
  'SDM',
  'ETHERNET_ACTIVATOR',
  'ERPNEXT',
])
