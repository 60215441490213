import { AnyZodObject, z } from 'zod'

import { UserId } from '../../index.js'
import {
  Order,
  order,
  OrderInitializer,
  orderInitializer,
  OrderMutator,
  orderMutator,
} from '../schemas/public/Order.js'
import {
  OrderLineItem,
  orderLineItem,
  OrderLineItemInitializer,
  orderLineItemInitializer,
  OrderLineItemMutator,
  orderLineItemMutator,
} from '../schemas/public/OrderLineItem.js'

export type ExtendedOrderInitializer = OrderInitializer & {
  items: OrderLineItemInitializer[]
}

export const extendedOrderInitializer = z
  .object({
    items: z.array(orderLineItemInitializer),
  })
  .merge(
    orderInitializer as unknown as AnyZodObject,
  ) as unknown as z.Schema<ExtendedOrderInitializer>

export type ExtendedOrderMutator = OrderMutator & {
  items: OrderLineItemMutator[]
}

export const extendedOrderMutator = z
  .object({
    items: z.array(orderLineItemMutator),
  })
  .merge(
    orderMutator as unknown as AnyZodObject,
  ) as unknown as z.Schema<ExtendedOrderMutator>

export type ExtendedOrder = Order & {
  items: OrderLineItem[]
  organizationName: string
  projectName: string
  createdBy: string
  createdAt: Date | string
  createdByUser: {
    id: UserId
    email: string
    nameFirst: string
    nameLast: string
  }
}

export const extendedOrder = z
  .object({
    items: z.array(orderLineItem),
  })
  .merge(order as unknown as AnyZodObject) as unknown as z.Schema<ExtendedOrder>
