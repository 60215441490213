import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  changeLogObjType,
  type default as ChangeLogObjType,
} from './ChangeLogObjType.js'

/** Identifier type for public.change_log_ */
export type ChangeLogId = number & { __brand: 'ChangeLogId' }

/** Represents the table public.change_log_ */
export default interface ChangeLogTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<ChangeLogId, ChangeLogId | undefined, ChangeLogId>

  /** Database type: pg_catalog.jsonb */
  changeActions: ColumnType<unknown, unknown, unknown>

  /** Database type: pg_catalog.int4 */
  objId: ColumnType<number | null, number | null, number | null>

  /** Database type: public.change_log_obj_type_ */
  objType: ColumnType<ChangeLogObjType, ChangeLogObjType, ChangeLogObjType>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.uuid */
  objUuid: ColumnType<string | null, string | null, string | null>
}

export type ChangeLog = Selectable<ChangeLogTable>

export type ChangeLogInitializer = Insertable<ChangeLogTable>

export type ChangeLogMutator = Updateable<ChangeLogTable>

export const changeLogId = z.number() as unknown as z.Schema<ChangeLogId>

export const changeLog = z.object({
  id           : changeLogId,
  changeActions: z.unknown(),
  objId        : z.number().nullable().optional(),
  objType      : changeLogObjType,
  createdAt    : z.coerce.date(),
  createdBy    : z.string().nullable().optional(),
  objUuid      : z.string().nullable().optional(),
}) as unknown as z.Schema<ChangeLog>

export const changeLogInitializer = z.object({
  id           : changeLogId.optional(),
  changeActions: z.unknown(),
  objId        : z.number().optional().nullable().optional(),
  objType      : changeLogObjType,
  createdAt    : z.coerce.date(),
  createdBy    : z.string().optional().nullable().optional(),
  objUuid      : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<ChangeLogInitializer>

export const changeLogMutator = z.object({
  id           : changeLogId.optional(),
  changeActions: z.unknown().optional(),
  objId        : z.number().optional().nullable().optional(),
  objType      : changeLogObjType.optional(),
  createdAt    : z.coerce.date().optional(),
  createdBy    : z.string().optional().nullable().optional(),
  objUuid      : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<ChangeLogMutator>
