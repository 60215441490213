import { z } from 'zod'

/** Represents the enum public.change_log_obj_type_ */
enum ChangeLogObjType {
  CABLE = 'CABLE',
  CABLE_HISTORY = 'CABLE_HISTORY',
  CABLE_LEAD_SECTION = 'CABLE_LEAD_SECTION',
  CABLE_BODY_SECTION = 'CABLE_BODY_SECTION',
  CABLE_EEPROM = 'CABLE_EEPROM',
  CABLE_PRICE = 'CABLE_PRICE',
  LOGGER = 'LOGGER',
  LOGGER_EVENT = 'LOGGER_EVENT',
  LOGGER_HISTORY = 'LOGGER_HISTORY',
  MODEM = 'MODEM',
  MODEM_HISTORY = 'MODEM_HISTORY',
  ORGANIZATION = 'ORGANIZATION',
  PROJECT = 'PROJECT',
  PROJECT_ALIAS = 'PROJECT_ALIAS',
  PROJECT_SITE = 'PROJECT_SITE',
  PROJECT_USERS = 'PROJECT_USERS',
  SITE = 'SITE',
  SITE_CABLE_HISTORY = 'SITE_CABLE_HISTORY',
  SITE_CABLE_HISTORY_LABELS = 'SITE_CABLE_HISTORY_LABELS',
  SITE_LABELS = 'SITE_LABELS',
  SITE_MODEM_HISTORY = 'SITE_MODEM_HISTORY',
  SITE_MODEM_HISTORY_LABELS = 'SITE_MODEM_HISTORY_LABELS',
  USER = 'USER',
  USER_AUTH = 'USER_AUTH',
  ORGANIZATION_PROJECT = 'ORGANIZATION_PROJECT',
  ROLE = 'ROLE',
  FEE_STRUCTURE = 'FEE_STRUCTURE',
  FEES = 'FEES',
  ASSESSED_FEES = 'ASSESSED_FEES',
  ASSESSED_OVERAGE_FEES = 'ASSESSED_OVERAGE_FEES',
  ASSESSED_LATE_FEES = 'ASSESSED_LATE_FEES',
  MAXIO_LOGGER_SUBSCRIPTION = 'MAXIO_LOGGER_SUBSCRIPTION',
  MAXIO_SUBSCRIPTION_NOTE = 'MAXIO_SUBSCRIPTION_NOTE',
  ORDER = 'ORDER',
  ORDER_LINE_ITEM = 'ORDER_LINE_ITEM',
  SERVICE_CONTRACT = 'SERVICE_CONTRACT',
  SERVICE_CONTRACT_ITEM = 'SERVICE_CONTRACT_ITEM',
  PRODUCT_SERVICE = 'PRODUCT_SERVICE',
  PRODUCT_SERVICE_PRICE_HISTORY = 'PRODUCT_SERVICE_PRICE_HISTORY',
  SERVICE_CONTRACT_PAYMENT = 'SERVICE_CONTRACT_PAYMENT',
}

export default ChangeLogObjType

/** Zod schema for change_log_obj_type_ */
export const changeLogObjType = z.enum([
  'CABLE',
  'CABLE_HISTORY',
  'CABLE_LEAD_SECTION',
  'CABLE_BODY_SECTION',
  'CABLE_EEPROM',
  'CABLE_PRICE',
  'LOGGER',
  'LOGGER_EVENT',
  'LOGGER_HISTORY',
  'MODEM',
  'MODEM_HISTORY',
  'ORGANIZATION',
  'PROJECT',
  'PROJECT_ALIAS',
  'PROJECT_SITE',
  'PROJECT_USERS',
  'SITE',
  'SITE_CABLE_HISTORY',
  'SITE_CABLE_HISTORY_LABELS',
  'SITE_LABELS',
  'SITE_MODEM_HISTORY',
  'SITE_MODEM_HISTORY_LABELS',
  'USER',
  'USER_AUTH',
  'ORGANIZATION_PROJECT',
  'ROLE',
  'FEE_STRUCTURE',
  'FEES',
  'ASSESSED_FEES',
  'ASSESSED_OVERAGE_FEES',
  'ASSESSED_LATE_FEES',
  'MAXIO_LOGGER_SUBSCRIPTION',
  'MAXIO_SUBSCRIPTION_NOTE',
  'ORDER',
  'ORDER_LINE_ITEM',
  'SERVICE_CONTRACT',
  'SERVICE_CONTRACT_ITEM',
  'PRODUCT_SERVICE',
  'PRODUCT_SERVICE_PRICE_HISTORY',
  'SERVICE_CONTRACT_PAYMENT',
])
