import { z } from 'zod'

/** Represents the enum public.product_service_type_ */
enum ProductServiceType {
  SOFTWARE = 'SOFTWARE',
  HARDWARE = 'HARDWARE',
  DATA_TRANSPORT = 'DATA_TRANSPORT',
}

export default ProductServiceType

/** Zod schema for product_service_type_ */
export const productServiceType = z.enum([
  'SOFTWARE',
  'HARDWARE',
  'DATA_TRANSPORT',
])
