import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Button,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Spacer,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import {
  ExtendedServiceContract,
  serviceContractPaymentInitializer,
  ServiceContractPaymentInitializer,
  ServicePaymentType,
} from '@beaded/models'

import { serviceManager } from 'api'

import { ModalContainer } from 'containers/ModalContainer'

import { useUserContext } from 'hooks/useUserContext'

import { logValidationErrors } from 'lib/logValidationErrors'
import { queryClient } from 'lib/react-query'

import { DefaultInput } from './inputs/DefaultInput'
import { SelectInput } from './inputs/SelectInput'
import { TextareaInput } from './inputs/TextAreaInput'

type CreateExternalPaymentProps = {
  data: ExtendedServiceContract
  iconButton?: React.ReactNode
  iconButtonProps?: any
  disabled?: boolean
  buttonText?: string
  buttonProps?: any
  tooltipText?: string
}

export const CreateExternalPaymentModal = (
  props: CreateExternalPaymentProps,
) => {
  const methods = useForm<ServiceContractPaymentInitializer>({
    resolver     : zodResolver(serviceContractPaymentInitializer),
    defaultValues: {
      serviceContractId: props.data.id,
    },
  })

  const user = useUserContext()
  const serviceContractPaymentTypes = Object.values(ServicePaymentType)

  const mutation = useMutation(
    async (data: ServiceContractPaymentInitializer) => {
      data.createdBy = user.currentUser?.id
      // TODO: @jasonwarta should it be assigned here or backend (my preferred)
      // with something like checkOrFixUserId?
      const validateResult = serviceContractPaymentInitializer.safeParse(data)

      if (validateResult.success === false) {
        logValidationErrors(validateResult.error)
        return
      }
      return serviceManager.createContractServicePayment(validateResult.data)
    },
    {
      onSuccess: () => {
        methods.reset()
        queryClient.invalidateQueries('serviceContracts')
      },
    },
  )

  const handler = (data: ServiceContractPaymentInitializer) => {
    mutation.mutateAsync(data)
  }
  return (
    <ModalContainer
      TriggerButton={({ onClick }: any) =>
        props.iconButton ? (
          <Tooltip label={props.tooltipText}>
            <IconButton
              icon={props.iconButton}
              onClick={onClick}
              disabled={props.disabled}
              {...props.iconButtonProps}
            />
          </Tooltip>
        ) : (
          <Tooltip label={props.tooltipText}>
            <Button
              onClick={onClick}
              disabled={props.disabled}
              {...props.buttonProps}
            >
              {props.buttonText}
            </Button>
          </Tooltip>
        )
      }
      modalProps={{ size: '2xl' }}
      header={`Contract #${props.data.contractNumber} - ${props.data.projectName}`}
      footer={() => (
        <VStack justifyContent='right' align='right' width='100%'>
          <HStack>
            <Spacer />
            {mutation.isSuccess && <CheckCircleIcon color='green.400' />}
            {mutation.isError && <WarningIcon color='red.400' />}
            <Button
              onClick={methods.handleSubmit(handler)}
              isLoading={mutation.isLoading}
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      )}
    >
      <FormProvider {...methods}>
        <form>
          <Grid templateColumns='repeat(4, 1fr)' gap={3}>
            <GridItem colSpan={2}>
              <DefaultInput
                name='paymentAmountInCents'
                type='number'
                label='Payment Amount'
                placeholder='Amount'
                hookformProps={{ required: true, valueAsNumber: true }}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <SelectInput
                name='servicePaymentType'
                label='Type'
                hookformProps={{}}
                options={serviceContractPaymentTypes}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <DefaultInput
                name='paymentReceivedAt'
                type='date'
                label='Payment Will be Recorded on this date:'
                readonly={true}
                hookformProps={{
                  required: true,
                  value   : new Date().toISOString().split('T')[0],
                }}
              />
            </GridItem>
            <GridItem colSpan={4}>
              <TextareaInput name='notes' label='Notes' />
            </GridItem>
          </Grid>
        </form>
      </FormProvider>
    </ModalContainer>
  )
}
