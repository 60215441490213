import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { productServiceId, type ProductServiceId } from './ProductService.js'
import {
  productServiceType,
  type default as ProductServiceType,
} from './ProductServiceType.js'
import { userId, type UserId } from './User.js'

/** Identifier type for public.product_service_history_ */
export type ProductServiceHistoryId = string & {
  __brand: 'ProductServiceHistoryId'
}

/** Represents the table public.product_service_history_ */
export default interface ProductServiceHistoryTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<
    ProductServiceHistoryId,
    ProductServiceHistoryId | undefined,
    ProductServiceHistoryId
  >

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.uuid */
  productServiceId: ColumnType<
    ProductServiceId,
    ProductServiceId,
    ProductServiceId
  >

  /** Database type: pg_catalog.text */
  serviceFullName: ColumnType<string, string, string>

  /** Database type: pg_catalog.text */
  serviceShortName: ColumnType<string, string, string>

  /** Database type: pg_catalog.int4 */
  priceInCents: ColumnType<number, number, number>

  /** Database type: pg_catalog.text */
  currencyType: ColumnType<string, string, string>

  /** Database type: public.product_service_type_ */
  productServiceType: ColumnType<
    ProductServiceType,
    ProductServiceType,
    ProductServiceType
  >

  /** Database type: pg_catalog.text */
  notes: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  skuNumber: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  productUnitType: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  productDurationType: ColumnType<string | null, string | null, string | null>
}

export type ProductServiceHistory = Selectable<ProductServiceHistoryTable>

export type ProductServiceHistoryInitializer =
  Insertable<ProductServiceHistoryTable>

export type ProductServiceHistoryMutator =
  Updateable<ProductServiceHistoryTable>

export const productServiceHistoryId =
  z.string() as unknown as z.Schema<ProductServiceHistoryId>

export const productServiceHistory = z.object({
  id                 : productServiceHistoryId,
  createdAt          : z.coerce.date().nullable().optional(),
  createdBy          : userId.nullable().optional(),
  updatedAt          : z.coerce.date().nullable().optional(),
  updatedBy          : userId.nullable().optional(),
  deletedAt          : z.coerce.date().nullable().optional(),
  deletedBy          : userId.nullable().optional(),
  productServiceId   : productServiceId,
  serviceFullName    : z.string(),
  serviceShortName   : z.string(),
  priceInCents       : z.number(),
  currencyType       : z.string(),
  productServiceType : productServiceType,
  notes              : z.string().nullable().optional(),
  skuNumber          : z.string().nullable().optional(),
  productUnitType    : z.string().nullable().optional(),
  productDurationType: z.string().nullable().optional(),
}) as unknown as z.Schema<ProductServiceHistory>

export const productServiceHistoryInitializer = z.object({
  id                 : productServiceHistoryId.optional(),
  createdAt          : z.coerce.date().optional().nullable().optional(),
  createdBy          : userId.optional().nullable().optional(),
  updatedAt          : z.coerce.date().optional().nullable().optional(),
  updatedBy          : userId.optional().nullable().optional(),
  deletedAt          : z.coerce.date().optional().nullable().optional(),
  deletedBy          : userId.optional().nullable().optional(),
  productServiceId   : productServiceId,
  serviceFullName    : z.string(),
  serviceShortName   : z.string(),
  priceInCents       : z.number(),
  currencyType       : z.string(),
  productServiceType : productServiceType,
  notes              : z.string().optional().nullable().optional(),
  skuNumber          : z.string().optional().nullable().optional(),
  productUnitType    : z.string().optional().nullable().optional(),
  productDurationType: z.string().optional().nullable().optional(),
}) as unknown as z.Schema<ProductServiceHistoryInitializer>

export const productServiceHistoryMutator = z.object({
  id                 : productServiceHistoryId.optional(),
  createdAt          : z.coerce.date().optional().nullable().optional(),
  createdBy          : userId.optional().nullable().optional(),
  updatedAt          : z.coerce.date().optional().nullable().optional(),
  updatedBy          : userId.optional().nullable().optional(),
  deletedAt          : z.coerce.date().optional().nullable().optional(),
  deletedBy          : userId.optional().nullable().optional(),
  productServiceId   : productServiceId.optional(),
  serviceFullName    : z.string().optional(),
  serviceShortName   : z.string().optional(),
  priceInCents       : z.number().optional(),
  currencyType       : z.string().optional(),
  productServiceType : productServiceType.optional(),
  notes              : z.string().optional().nullable().optional(),
  skuNumber          : z.string().optional().nullable().optional(),
  productUnitType    : z.string().optional().nullable().optional(),
  productDurationType: z.string().optional().nullable().optional(),
}) as unknown as z.Schema<ProductServiceHistoryMutator>
