/**
 * Get the title for a table based on the text and input
 * @param text - The text to display. If given a simple string, this will return the provided text.
 *               If given a string with both %s and %d, %s will be used for the plural form and %d will be used for the count
 * @param input - An array input. this is used to determine if the text should be plural
 * @returns The title for the table
 */
export const getTableTitle = (text: string, input?: any[]) => {
  if (!input) return text

  if (text.includes('%d') && text.includes('%s')) {
    return text
      .replace('%d', `${input.length}`)
      .replace('%s', input.length > 1 ? 's' : '')
  }

  return `${input.length > 0 ? `${input.length} ` : ''}${text}${
    input.length > 1 ? 's' : ''
  }`
}
